import React, { useEffect, useState } from 'react';
import { http } from '../../helper/http'; // Adjust the import path based on your project structure
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TablePagination, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, tableCellClasses } from '@mui/material';
import { useTemplateState } from '../../app-context/template-context/TemplateState';
import { setTemplate } from '../../app-context/template-context/TemplateAction';
import { useUserState } from '../../app-context/auth-context/AuthState';
import ProtectedComponent from '../../utils/protected-route/ProtectedComponent';
import indianStateCityData from '../../helper/indianStateCityData';
const baseUrl = process.env.REACT_APP_BASE_URL;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
    backgroundColor: theme.palette.grey[200], // Customize the hover color here
  },
  }));

const SchedulerAlertData = () => {
    const [alertData, setAlertData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState<number>(0); // Zero-based index for Material-UI
    const [rowsPerPage, setRowsPerPage] = useState<number>(10); // Default rows per page
    const [totalItems, setTotalItems] = useState<number>(0);
    const [filterActive, setFilterActive] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>("");
    const [selectedState, setSelectedState] = useState<any>("");
    const [selectedCity, setSelectedCity] = useState<any>("");
    const [city, setCity] = useState<any>([]);
    const [area, setArea] = useState<any>("");
    const [allAreas, setAllAreas] = useState<any>([]);
    const navigate = useNavigate();
    const {users} = useUserState();

    const tableHeader = [
        // { id: "deviceId", label: "DeviceId", align: "center" },
        { id: "deviceName", label: "DeviceName", align: "center" },
        { id: "deviceAlias", label: "DeviceAlias", align: "center" },
        { id: "lastAlertTrigger", label: "LastAlertTrigger", align: "center" },
        { id: "actions", label: "Actions", align: "center" },
    ]

    const templateList = [
        {id: "45", templateName: "AWFIS_ENERGY_METER" },
        {id: "43", templateName: "Buzz Monitoring"},
        {id: "42", templateName: "AC Controller"}
    ]
    const fetchAlertData = async (
        page: number,
        rowsPerPage: number,
        state?: string,
        city?: string,
        area?: string,
        template?: string
    ) => {
        setLoading(true);
        setError(null);
    
        try {
            // Construct API URL based on the filter state
            const params = new URLSearchParams({
                page: (page + 1).toString(),
                rowsPerPage: rowsPerPage.toString(),
            });
    
            if (!filterActive) {
                if (state) params.append('state', state);
                if (city) params.append('city', city || "");
                if (area) params.append('area', area || "");
                if(template) params.append('template', template);
            }
    
            const response = (await http(`${baseUrl}/alert-log?${params.toString()}`)) as any;
    
            const alertLogs = response.alertLogs || [];
            const allAreas = Array.from(new Set(alertLogs.map((log: any) => log.area)));
    
            setAllAreas(response.allAreas);
            setAlertData(alertLogs);
            setPage(Number(response.currentPage) - 1); // Convert to zero-based index
            setTotalItems(response.totalItems);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch alert data.');
            setLoading(false);
        }
    };
    
    useEffect(() => {
            fetchAlertData(page, rowsPerPage, selectedState, selectedCity, area, selectedTemplate);
    }, [page, rowsPerPage, selectedState, selectedCity, area, selectedTemplate]);
    
    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rows per page changes
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const handleLogsButtonClick = (logs: any, deviceName: any) => {
        navigate('/alert-device-logs', { state: { logs, deviceName } }); // Navigate and pass logs data
    };

    const handleStateChange = (event: SelectChangeEvent<string>) => {
        setSelectedState(event.target.value);
        setSelectedCity("");
        const selectedState1 = indianStateCityData.states.find(
            (state) => state.name === event.target.value
        );
        setCity(selectedState1?.cities);
        setArea("");
        setAllAreas([]);
    };

    const handleCityChange = (event: SelectChangeEvent<string>) => {
        setSelectedCity(event.target.value);
        setArea("");
        setAllAreas([]);
    };

    const handleAreaChange = (event: any) => {
        setArea(event.target.value);
    };

    return (
        <div>
            <h1 className='text-2xl font-semibold'>Scheduler Alert Data</h1>
            <div className='p-4'>
                <div className='flex justify-between items-center'>
                        <div className='flex gap-4'>
                            <ProtectedComponent roleids={[1]}>
                                <FormControl variant="outlined" className="w-full sm:w-60">
                                    <InputLabel>Template</InputLabel>
                                    <Select value={selectedTemplate || ""} onChange={(e) => {setSelectedTemplate(e.target.value); setSelectedState(""); setSelectedCity(""); setArea("")}} label="Template" fullWidth>
                                        {templateList.map((template: any) => (
                                            <MenuItem key={template.id} value={template.id}>{template.templateName}</MenuItem>
                                        ))}
                                    </Select> 
                                </FormControl>
                            </ProtectedComponent>
                            <FormControl variant="outlined" className="w-full sm:w-60">
                                <InputLabel>State</InputLabel>
                                <Select
                                    value={selectedState || ""}
                                    onChange={handleStateChange}
                                    label="State"
                                    fullWidth
                                >
                                    {indianStateCityData.states.map((stateObj) => (
                                        <MenuItem key={stateObj.name} value={stateObj.name}>
                                            {stateObj.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* City Dropdown */}
                            <FormControl variant="outlined" className="w-full sm:w-60 mr-4">
                                <InputLabel>City</InputLabel>
                                <Select
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    label="City"
                                    fullWidth
                                    disabled={!selectedState}
                                >
                                    {city.map((cityName: any) => (
                                        <MenuItem key={cityName} value={cityName}>
                                            {cityName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" className="w-full sm:w-60">
                                <InputLabel>Center</InputLabel>
                                <Select
                                    value={area}
                                    onChange={handleAreaChange}
                                    label="Area"
                                    fullWidth
                                    disabled={!selectedCity}
                                >
                                    {Array.isArray(allAreas) && allAreas.length > 0 ? (
                                        allAreas.map((areaName: any) => (
                                            <MenuItem key={areaName} value={areaName}>
                                                {areaName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No areas available</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                </div>
            </div>
                <>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className='bg-gray-800'>
                                    <TableRow className='border-b bg-gray-800' sx={{ backgroundColor: 'primary.main' }}>
                                        {tableHeader.map((header) => (
                                            <StyledTableCell key={header.id}>{header.label}</StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {alertData && alertData.length > 0 ? alertData.map((row: any) => (
                                        <StyledTableRow key={row.id}>
                                            {/* <StyledTableCell>{row.deviceId}</StyledTableCell> */}
                                            <StyledTableCell>{row.deviceName}</StyledTableCell>
                                            <StyledTableCell>{row.deviceAlias}</StyledTableCell>
                                            <StyledTableCell>
                                                {row.lastAlertTriggerTime
                                                    ? new Intl.DateTimeFormat('en-US', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                    }).format(new Date(row.lastAlertTriggerTime))
                                                    : 'N/A'}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleLogsButtonClick(row.logs, row.deviceName)}
                                                >
                                                    Logs
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={tableHeader.length} align="center">
                                                No data available
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
                            component="div"
                            count={totalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Paper>
                </>
        </div>
    );
};

export default SchedulerAlertData;
