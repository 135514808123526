import { http } from '../../helper/http'; // Adjust the path based on your project structure

/**
 * Notification type representing the structure of a notification object.
 */
export interface Notification {
  id: string;
  title: string;
  message: string;
  createdAt: string;
  [key: string]: any; // Add this for any additional dynamic fields
}

/**
 * Fetch notifications and optionally mark all as read.
 * @param {string} baseUrl - The base URL for the API.
 * @param {string} customerId - The ID of the customer.
 * @param {boolean} markAllRead - Whether to mark all notifications as read.
 * @returns {Promise<Notification[]>} - A promise resolving to the notifications array.
 */
export const fetchNotifications = async (
  baseUrl: string,
  customerId: string,
  markAllRead: boolean = false
): Promise<Notification[]> => {
  try {
    // Fetch updated notifications
    const response = await http(`${baseUrl}/notification?customerId=${customerId}`, 'GET');
    return Array.isArray(response) && response.length > 0 ? response : [];
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};
