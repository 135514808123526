import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface ChartData {
  name: string;
  data: {
    x: string;
    y: number;
  }[];
  color?: string;
  unit?: string;
}

interface StackedBarChartProps {
  data: ChartData[];
  barsLabel?: 'horizontal' | 'vertical';
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data = [], barsLabel = 'horizontal' }) => {
  if (!data || data.length === 0) {
    return (
      <div className="w-full h-96 bg-gray-100 rounded-lg flex items-center justify-center">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  const unit = data[0]?.unit || 'kWh';

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric' 
      });
    } catch (error) {
      return dateString;
    }
  };

  const categories = data[0]?.data?.map(item => formatDate(item.x)) || [];
  
  const series = data.map(series => ({
    name: series?.name || "Graph",
    data: series?.data.map(item => item.y || 0)
  }));

  const colors = data.map(series => series.color || '#000000');

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        columnWidth: '70%',
        dataLabels: {
          total: {
            enabled: false,
            // style: {
            //   fontSize: '1px',
            //   fontWeight: 900
            // }
          },
          orientation: barsLabel === 'vertical' ? 'vertical' : 'horizontal',
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: categories,
      labels: {
        rotate: -45,
        rotateAlways: false,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: `Average ${unit}`
      },
      labels: {
        formatter: (value) => Number(value).toFixed()
      }
    },
    legend: {
      position: 'bottom', // Changed from 'right' to 'bottom'
      horizontalAlign: 'center', // Center align the legend
      offsetY: 7, // Adjusted offset from bottom
      offsetX: 0,
      itemMargin: { // Add some spacing between legend items
        horizontal: 10,
        vertical: 0
      }
    },
    fill: {
      opacity: 1
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1
        }
      }
    },
    colors: colors,
    tooltip: {
      y: {
        formatter: (value) => `${Number(value).toFixed(3)} ${unit}`
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => Number(value).toFixed(3)
    }
  };

  return (
    <div className="w-full h-96">
      <div id="chart" className="h-full">
        <ReactApexChart 
          options={options}
          series={series}
          type="bar"
          height="100%"
        />
      </div>
    </div>
  );
};

export default StackedBarChart;