import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { Checkbox, Dialog, IconButton, CircularProgress } from "@mui/material";
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import CustomLineChart from "../../../components/deviceComponents/CustomLineChart";
import { http } from "../../../helper/http";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { JwtPayload, jwtDecode } from "jwt-decode";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBoxFilter from "../../../components/CheckboxField";
import {
  formatIndianTime,
  generateCSV,
  reportData1,
} from "../../../common-function/CommonFunction";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { generateCSV1, reportData } from "../../../common-function/Report";
// import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import * as XLSX from 'xlsx';
import TabularComponents2 from "../../../components/deviceComponents/TabularComponents2";
import { useUserState } from "../../../app-context/auth-context/AuthState";
let header: any = [
  "TimeStamp",
  "Flow",
  "Flow unit",
  // "POSITIVECUMULATIVE",
  // "NEGATIVECUMULATIVE",
  "Total",
  "Total unit",
];

let energy: any = [
  "TimeStamp",
  "AverageVoltage",
  "AverageCurrent",
  "TotalActivePower",
  "TotalReactivePower",
  "TotalApparentPower",
  "Frequency",
  "RPhaseVoltage",
  "YPhaseVoltage",
  "YBPhaseVoltage",
  "BPhaseVoltage",
  "RYPhaseVoltage",
  "RBPhaseVoltage",
  "RPhaseCurrent",
  "YPhaseCurrent",
  "BPhaseCurrent",
  "PowerFactor",
  "RPhasePowerFactor",
  "YPhasePowerFactor",
  "BPhasePowerFactor",
  "RPhaseActivePower",
  "YPhaseActivePower",
  "BPhaseActivePower",
  "RPhaseReactivePower",
  "YPhaseReactivePower",
  "BPhaseReactivePower",
  "RPhaseApparentPower",
  "YPhaseApparentPower",
  "BPhaseApparentPower",
  "ActiveEnergy",
  "ReactiveEnergy",
  "ApparentEnergy",

  "rvtgphthd",
  "yvtgphthd",
  "bvtgphthd",
  "rcrtphthd",
  "ycrtphthd",
  "bcrtphthd",
];

const baseUrl = process.env.REACT_APP_BASE_URL;
const Data = () => {
  const location = useLocation();
  const { deviceId, deviceState }: any = location?.state || {};
  const { users }: any = useUserState();
  // console.log("users", users);
  

  const [template, setTemplate]: any = useState(null);
  const [deviceName, setDeviceName]: any = useState("");
  const [headers, setHeaders]: any = useState<any>([]);
  const [optionColumns, setOptionColumns]: any = useState<any[]>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [apiData1, setApiData1] = useState<any>([]);
  const [loading, setLoading] = useState(false);


  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    nextPage: 0,
    previousPage: null,
  });
  const handlePageChange = (event: unknown, newPage: number) => {
    const direction = newPage > pagination.currentPage - 1 ? "next" : "previous";
    const page = +pagination.currentPage;
    const d = direction === "next" ? page + 1 : page - 1;
    if (startValue && endValue) {
      const startDate = startValue.toDate();
      const endDate = endValue.toDate();
      if (!isNaN(startDate) && !isNaN(endDate)) {
        getData({ startDate, endDate, deviceId, page: d, limit: rowsPerPage });
      }
    }
  };


  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (startValue && endValue) {
      const startDate = startValue.toDate();
      const endDate = endValue.toDate();
      if (!isNaN(startDate) && !isNaN(endDate)) {
        getData({ startDate, endDate, deviceId, page: 1, limit: event.target.value });
      }
    }

  };
  // const levelHeaders = ["TimeStamp", "Level"];
  const [selected, setSelected] = useState<any[]>([]);
  const [filterTable, setFilterTable] = useState(false);
  // const [column, setColumn] = useState<any>(headers);
  const [device, setDevice] = useState<any>(null);

  const [token] = useState(window.localStorage.getItem("token"));
  const [customTag, setCustomTag] = useState<any>({});
  const [customTag1, setCustomTag1] = useState<any>({});
  const deviceTemplate = deviceState?.DeviceTemplate;

  const fetchCustomTag1 = async () => {
    try {
      const response: any = await http(
        `${baseUrl}/device-template?deviceId=${deviceId}`
      );
      const transformedTagJson = response.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTag1(transformedTagJson);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCustomTag1();
  }, [deviceId]);

  let dummyFilterData: any = [
    "#",
    "TimeStamp",
    customTag1 && !isEmpty(customTag1) && customTag1.t_acte
      ? customTag1.t_acte.sensorStatus
        ? `${customTag1.t_acte.sensorAlias} (${customTag1.t_acte.sensorUnit})`
        : ""
      : "Active Energy",
    customTag1 && !isEmpty(customTag1) && customTag1.t_re
      ? customTag1.t_re.sensorStatus
        ? `${customTag1.t_re.sensorAlias} (${customTag1.t_re.sensorUnit})`
        : ""
      : "Reactive Energy",
    customTag1 && !isEmpty(customTag1) && customTag1.t_apte
      ? customTag1.t_apte.sensorStatus
        ? `${customTag1.t_apte.sensorAlias} (${customTag1.t_apte.sensorUnit})`
        : ""
      : "Apparent Energy",
    customTag1 && !isEmpty(customTag1) && customTag1.imp_ae
      ? customTag1.imp_ae.sensorStatus
        ? `${customTag1.imp_ae.sensorAlias} (${customTag1.imp_ae.sensorUnit})`
        : ""
      : "Import Active",
    customTag1 && !isEmpty(customTag1) && customTag1.imp_re
      ? customTag1.imp_re.sensorStatus
        ? `${customTag1.imp_re.sensorAlias} (${customTag1.imp_re.sensorUnit})`
        : ""
      : "Import Reactive",
    customTag1 && !isEmpty(customTag1) && customTag1.exp_ae
      ? customTag1.exp_ae.sensorStatus
        ? `${customTag1.exp_ae.sensorAlias} (${customTag1.exp_ae.sensorUnit})`
        : ""
      : "Export Active",
    customTag1 && !isEmpty(customTag1) && customTag1.exp_re
      ? customTag1.exp_re.sensorStatus
        ? `${customTag1.exp_re.sensorAlias} (${customTag1.exp_re.sensorUnit})`
        : ""
      : "Export Reactive",
    customTag1 && !isEmpty(customTag1) && customTag1.av_ln
      ? customTag1.av_ln.sensorStatus
        ? `${customTag1.av_ln.sensorAlias} (${customTag1.av_ln.sensorUnit})`
        : ""
      : "Average Neutral",
    customTag1 && !isEmpty(customTag1) && customTag1.v1n
      ? customTag1.v1n.sensorStatus
        ? `${customTag1.v1n.sensorAlias} (${customTag1.v1n.sensorUnit})`
        : ""
      : "R Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.v2n
      ? customTag1.v2n.sensorStatus
        ? `${customTag1.v2n.sensorAlias} (${customTag1.v2n.sensorUnit})`
        : ""
      : "Y Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.v3n
      ? customTag1.v3n.sensorStatus
        ? `${customTag1.v3n.sensorAlias} (${customTag1.v3n.sensorUnit})`
        : ""
      : "B Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.av_ll
      ? customTag1.av_ll.sensorStatus
        ? `${customTag1.av_ll.sensorAlias} (${customTag1.av_ll.sensorUnit})`
        : ""
      : "Average Line-Line",
    customTag1 && !isEmpty(customTag1) && customTag1.v12
      ? customTag1.v12.sensorStatus
        ? `${customTag1.v12.sensorAlias} (${customTag1.v12.sensorUnit})`
        : ""
      : "RY Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.v23
      ? customTag1.v23.sensorStatus
        ? `${customTag1.v23.sensorAlias} (${customTag1.v23.sensorUnit})`
        : ""
      : "YB Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.v31
      ? customTag1.v31.sensorStatus
        ? `${customTag1.v31.sensorAlias} (${customTag1.v31.sensorUnit})`
        : ""
      : "RB Phase(V)",
    customTag1 && !isEmpty(customTag1) && customTag1.av_c
      ? customTag1.av_c.sensorStatus
        ? `${customTag1.av_c.sensorAlias} (${customTag1.av_c.sensorUnit})`
        : ""
      : "Average Current",
    customTag1 && !isEmpty(customTag1) && customTag1.cl1
      ? customTag1.cl1.sensorStatus
        ? `${customTag1.cl1.sensorAlias} (${customTag1.cl1.sensorUnit})`
        : ""
      : "R Phase(A)",
    customTag1 && !isEmpty(customTag1) && customTag1.cl2
      ? customTag1.cl2.sensorStatus
        ? `${customTag1.cl2.sensorAlias} (${customTag1.cl2.sensorUnit})`
        : ""
      : "Y Phase(A)",
    customTag1 && !isEmpty(customTag1) && customTag1.cl3
      ? customTag1.cl3.sensorStatus
        ? `${customTag1.cl3.sensorAlias} (${customTag1.cl3.sensorUnit})`
        : ""
      : "B Phase(A)",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kw
      ? customTag1.t_kw.sensorStatus
        ? `${customTag1.t_kw.sensorAlias} (${customTag1.t_kw.sensorUnit})`
        : ""
      : "Total Active",
    customTag1 && !isEmpty(customTag1) && customTag1.kw1
      ? customTag1.kw1.sensorStatus
        ? `${customTag1.kw1.sensorAlias} (${customTag1.kw1.sensorUnit})`
        : ""
      : "R Phase(kW)",
    customTag1 && !isEmpty(customTag1) && customTag1.kw2
      ? customTag1.kw2.sensorStatus
        ? `${customTag1.kw2.sensorAlias} (${customTag1.kw2.sensorUnit})`
        : ""
      : "Y Phase(kW)",
    customTag1 && !isEmpty(customTag1) && customTag1.kw3
      ? customTag1.kw3.sensorStatus
        ? `${customTag1.kw3.sensorAlias} (${customTag1.kw3.sensorUnit})`
        : ""
      : "B Phase(kW)",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kvar
      ? customTag1.t_kvar.sensorStatus
        ? `${customTag1.t_kvar.sensorAlias} (${customTag1.t_kvar.sensorUnit})`
        : ""
      : "Total Reactive",
    customTag1 && !isEmpty(customTag1) && customTag1.kvar1
      ? customTag1.kvar1.sensorStatus
        ? `${customTag1.kvar1.sensorAlias} (${customTag1.kvar1.sensorUnit})`
        : ""
      : "R Phase(kVAr)",
    customTag1 && !isEmpty(customTag1) && customTag1.kvar2
      ? customTag1.kvar2.sensorStatus
        ? `${customTag1.kvar2.sensorAlias} (${customTag1.kvar2.sensorUnit})`
        : ""
      : "Y Phase(kVAr)",
    customTag1 && !isEmpty(customTag1) && customTag1.kvar3
      ? customTag1.kvar3.sensorStatus
        ? `${customTag1.kvar3.sensorAlias} (${customTag1.kvar3.sensorUnit})`
        : ""
      : "B Phase(kVAr)",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kva
      ? customTag1.t_kva.sensorStatus
        ? `${customTag1.t_kva.sensorAlias} (${customTag1.t_kva.sensorUnit})`
        : ""
      : "Total Apparent",
    customTag1 && !isEmpty(customTag1) && customTag1.kva1
      ? customTag1.kva1.sensorStatus
        ? `${customTag1.kva1.sensorAlias} (${customTag1.kva1.sensorUnit})`
        : ""
      : "R Phase(kVa)",
    customTag1 && !isEmpty(customTag1) && customTag1.kva2
      ? customTag1.kva2.sensorStatus
        ? `${customTag1.kva2.sensorAlias} (${customTag1.kva2.sensorUnit})`
        : ""
      : "Y Phase(kVa)",
    customTag1 && !isEmpty(customTag1) && customTag1.kva3
      ? customTag1.kva3.sensorStatus
        ? `${customTag1.kva3.sensorAlias} (${customTag1.kva3.sensorUnit})`
        : ""
      : "B Phase(kVa)",
    customTag1 && !isEmpty(customTag1) && customTag1.av_pf
      ? customTag1.av_pf.sensorStatus
        ? `${customTag1.av_pf.sensorAlias} (${customTag1.av_pf.sensorUnit})`
        : ""
      : "Average PF",
    customTag1 && !isEmpty(customTag1) && customTag1.pf1
      ? customTag1.pf1.sensorStatus
        ? `${customTag1.pf1.sensorAlias} (${customTag1.pf1.sensorUnit})`
        : ""
      : "R Phase",
    customTag1 && !isEmpty(customTag1) && customTag1.pf2
      ? customTag1.pf2.sensorStatus
        ? `${customTag1.pf2.sensorAlias} (${customTag1.pf2.sensorUnit})`
        : ""
      : "Y Phase",
    customTag1 && !isEmpty(customTag1) && customTag1.pf3
      ? customTag1.pf3.sensorStatus
        ? `${customTag1.pf3.sensorAlias} (${customTag1.pf3.sensorUnit})`
        : ""
      : "B Phase",
    customTag1 && !isEmpty(customTag1) && customTag1.freq
      ? customTag1.freq.sensorStatus
        ? `${customTag1.freq.sensorAlias} (${customTag1.freq.sensorUnit})`
        : ""
      : "Frequency",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v1n
      ? customTag1.thd_v1n.sensorStatus
        ? `${customTag1.thd_v1n.sensorAlias} (${customTag1.thd_v1n.sensorUnit})`
        : ""
      : "R Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v2n
      ? customTag1.thd_v2n.sensorStatus
        ? `${customTag1.thd_v2n.sensorAlias} (${customTag1.thd_v2n.sensorUnit})`
        : ""
      : "Y Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v3n
      ? customTag1.thd_v3n.sensorStatus
        ? `${customTag1.thd_v3n.sensorAlias} (${customTag1.thd_v3n.sensorUnit})`
        : ""
      : "B Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v12
      ? customTag1.thd_v12.sensorStatus
        ? `${customTag1.thd_v12.sensorAlias} (${customTag1.thd_v12.sensorUnit})`
        : ""
      : "RY Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v23
      ? customTag1.thd_v23.sensorStatus
        ? `${customTag1.thd_v23.sensorAlias} (${customTag1.thd_v23.sensorUnit})`
        : ""
      : "YB Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_v31
      ? customTag1.thd_v31.sensorStatus
        ? `${customTag1.thd_v31.sensorAlias} (${customTag1.thd_v31.sensorUnit})`
        : ""
      : "RB Phase Volt(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_ci1
      ? customTag1.thd_ci1.sensorStatus
        ? `${customTag1.thd_ci1.sensorAlias} (${customTag1.thd_ci1.sensorUnit})`
        : ""
      : "R Phase Curr(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_ci2
      ? customTag1.thd_ci2.sensorStatus
        ? `${customTag1.thd_ci2.sensorAlias} (${customTag1.thd_ci2.sensorUnit})`
        : ""
      : "Y Phase Curr(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.thd_ci3
      ? customTag1.thd_ci3.sensorStatus
        ? `${customTag1.thd_ci3.sensorAlias} (${customTag1.thd_ci3.sensorUnit})`
        : ""
      : "B Phase Curr(%)",
    customTag1 && !isEmpty(customTag1) && customTag1.ap_maxd
      ? customTag1.ap_maxd.sensorStatus
        ? `${customTag1.ap_maxd.sensorAlias} (${customTag1.ap_maxd.sensorUnit})`
        : ""
      : "Max Active Power",
    customTag1 && !isEmpty(customTag1) && customTag1.ap_mind
      ? customTag1.ap_mind.sensorStatus
        ? `${customTag1.ap_mind.sensorAlias} (${customTag1.ap_mind.sensorUnit})`
        : ""
      : "Min Active Power",
    customTag1 && !isEmpty(customTag1) && customTag1.rp_maxd
      ? customTag1.rp_maxd.sensorStatus
        ? `${customTag1.rp_maxd.sensorAlias} (${customTag1.rp_maxd.sensorUnit})`
        : ""
      : "Max Reactive Power",
    customTag1 && !isEmpty(customTag1) && customTag1.rp_mind
      ? customTag1.rp_mind.sensorStatus
        ? `${customTag1.rp_mind.sensorAlias} (${customTag1.rp_mind.sensorUnit})`
        : ""
      : "Min Reactive Power",
    customTag1 && !isEmpty(customTag1) && customTag1.apr_maxd
      ? customTag1.apr_maxd.sensorStatus
        ? `${customTag1.apr_maxd.sensorAlias} (${customTag1.apr_maxd.sensorUnit})`
        : ""
      : "Max Apparent Power",
    customTag1 && !isEmpty(customTag1) && customTag1.r_hour
      ? customTag1.r_hour.sensorStatus
        ? `${customTag1.r_hour.sensorAlias} (${customTag1.r_hour.sensorUnit})`
        : ""
      : "Run Hours",
    customTag1 && !isEmpty(customTag1) && customTag1.a_intrpt
      ? customTag1.a_intrpt.sensorStatus
        ? `${customTag1.a_intrpt.sensorAlias} (${customTag1.a_intrpt.sensorUnit})`
        : ""
      : "Current Interrupt",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv1n
      ? customTag1.max_iv1n.sensorStatus
        ? `${customTag1.max_iv1n.sensorAlias} (${customTag1.max_iv1n.sensorUnit})`
        : ""
      : "R Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv2n
      ? customTag1.max_iv2n.sensorStatus
        ? `${customTag1.max_iv2n.sensorAlias} (${customTag1.max_iv2n.sensorUnit})`
        : ""
      : "Y Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv3n
      ? customTag1.max_iv3n.sensorStatus
        ? `${customTag1.max_iv3n.sensorAlias} (${customTag1.max_iv3n.sensorUnit})`
        : ""
      : "B Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv12
      ? customTag1.max_iv12.sensorStatus
        ? `${customTag1.max_iv12.sensorAlias} (${customTag1.max_iv12.sensorUnit})`
        : ""
      : "RY Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv23
      ? customTag1.max_iv23.sensorStatus
        ? `${customTag1.max_iv23.sensorAlias} (${customTag1.max_iv23.sensorUnit})`
        : ""
      : "YB Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_iv31
      ? customTag1.max_iv31.sensorStatus
        ? `${customTag1.max_iv31.sensorAlias} (${customTag1.max_iv31.sensorUnit})`
        : ""
      : "RB Phase Volt(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_ici1
      ? customTag1.max_ici1.sensorStatus
        ? `${customTag1.max_ici1.sensorAlias} (${customTag1.max_ici1.sensorUnit})`
        : ""
      : "R Phase Curr(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_ici2
      ? customTag1.max_ici2.sensorStatus
        ? `${customTag1.max_ici2.sensorAlias} (${customTag1.max_ici2.sensorUnit})`
        : ""
      : "Y Phase Curr(max A)",
    customTag1 && !isEmpty(customTag1) && customTag1.max_ici3
      ? customTag1.max_ici3.sensorStatus
        ? `${customTag1.max_ici3.sensorAlias} (${customTag1.max_ici3.sensorUnit})`
        : ""
      : "B Phase Curr(max A)",
  ];

  const energyMonitoring: any = dummyFilterData.filter(
    (item: any) => item !== ""
  );

  let dummyFilterData1: any = [
    "#",
    "TimeStamp",
    customTag1 && !isEmpty(customTag1) && customTag1.t_acte
      ? customTag1.t_acte.sensorStatus
        ? `${customTag1.t_acte.sensorAlias} (${customTag1.t_acte.sensorUnit})`
        : ""
      : "Active Energy",

    customTag1 && !isEmpty(customTag1) && customTag1.t_re
      ? customTag1.t_re.sensorStatus
        ? `${customTag1.t_re.sensorAlias} (${customTag1.t_re.sensorUnit})`
        : ""
      : "Reactive Energy",

    customTag1 && !isEmpty(customTag1) && customTag1.t_apte
      ? customTag1.t_apte.sensorStatus
        ? `${customTag1.t_apte.sensorAlias} (${customTag1.t_apte.sensorUnit})`
        : ""
      : "Apparent Energy",

    customTag1 && !isEmpty(customTag1) && customTag1.r_hr
      ? customTag1.r_hr.sensorStatus
        ? `${customTag1.r_hr.sensorAlias} (${customTag1.r_hr.sensorUnit})`
        : ""
      : "Run Hours",

    customTag1 && !isEmpty(customTag1) && customTag1.v
      ? customTag1.v.sensorStatus
        ? `${customTag1.v.sensorAlias} (${customTag1.v.sensorUnit})`
        : ""
      : "Voltage",

    customTag1 && !isEmpty(customTag1) && customTag1.a
      ? customTag1.a.sensorStatus
        ? `${customTag1.a.sensorAlias} (${customTag1.a.sensorUnit})`
        : ""
      : "Current",

    customTag1 && !isEmpty(customTag1) && customTag1.kW
      ? customTag1.kW.sensorStatus
        ? `${customTag1.kW.sensorAlias} (${customTag1.kW.sensorUnit})`
        : ""
      : "Active Power",

    customTag1 && !isEmpty(customTag1) && customTag1.kvar
      ? customTag1.kvar.sensorStatus
        ? `${customTag1.kvar.sensorAlias} (${customTag1.kvar.sensorUnit})`
        : ""
      : "Reactive Power",

    customTag1 && !isEmpty(customTag1) && customTag1.kva
      ? customTag1.kva.sensorStatus
        ? `${customTag1.kva.sensorAlias} (${customTag1.kva.sensorUnit})`
        : ""
      : "Apparent Power",

    customTag1 && !isEmpty(customTag1) && customTag1.hz
      ? customTag1.hz.sensorStatus
        ? `${customTag1.hz.sensorAlias} (${customTag1.hz.sensorUnit})`
        : ""
      : "Frequncy",

    customTag1 && !isEmpty(customTag1) && customTag1.pf
      ? customTag1.pf.sensorStatus
        ? `${customTag1.pf.sensorAlias} (${customTag1.pf.sensorUnit})`
        : ""
      : "Power Factor",

    customTag1 && !isEmpty(customTag1) && customTag1.ex1
      ? customTag1.ex1.sensorStatus
        ? `${customTag1.ex1.sensorAlias} (${customTag1.ex1.sensorUnit})`
        : ""
      : "Extra 1",

    customTag1 && !isEmpty(customTag1) && customTag1.ex2
      ? customTag1.ex2.sensorStatus
        ? `${customTag1.ex2.sensorAlias} (${customTag1.ex2.sensorUnit})`
        : ""
      : "Extra 2",

    customTag1 && !isEmpty(customTag1) && customTag1.ex3
      ? customTag1.ex3.sensorStatus
        ? `${customTag1.ex3.sensorAlias} (${customTag1.ex3.sensorUnit})`
        : ""
      : "Extra 3",

    customTag1 && !isEmpty(customTag1) && customTag1.ex4
      ? customTag1.ex4.sensorStatus
        ? `${customTag1.ex4.sensorAlias} (${customTag1.ex4.sensorUnit})`
        : ""
      : "Extra 4",

    customTag1 && !isEmpty(customTag1) && customTag1.ex5
      ? customTag1.ex5.sensorStatus
        ? `${customTag1.ex5.sensorAlias} (${customTag1.ex5.sensorUnit})`
        : ""
      : "Extra 5",
  ];


  let dummyFilterData2: any = [
    "#",
    "TimeStamp",
    customTag1 && !isEmpty(customTag1) && customTag1.v1
      ? customTag1.v1.sensorStatus
        ? `${customTag1.v1.sensorAlias} (${customTag1.v1.sensorUnit})`
        : ""
      : "Voltage 1",
    customTag1 && !isEmpty(customTag1) && customTag1.v2
      ? customTag1.v2.sensorStatus
        ? `${customTag1.v2.sensorAlias} (${customTag1.v2.sensorUnit})`
        : ""
      : "Voltage 2",
    customTag1 && !isEmpty(customTag1) && customTag1.v3
      ? customTag1.v3.sensorStatus
        ? `${customTag1.v3.sensorAlias} (${customTag1.v3.sensorUnit})`
        : ""
      : "Voltage 3",
    customTag1 && !isEmpty(customTag1) && customTag1.a_v
      ? customTag1.a_v.sensorStatus
        ? `${customTag1.a_v.sensorAlias} (${customTag1.a_v.sensorUnit})`
        : ""
      : "Average Voltage",
    customTag1 && !isEmpty(customTag1) && customTag1.cl1
      ? customTag1.cl1.sensorStatus
        ? `${customTag1.cl1.sensorAlias} (${customTag1.cl1.sensorUnit})`
        : ""
      : "Current 1",
    customTag1 && !isEmpty(customTag1) && customTag1.cl2
      ? customTag1.cl2.sensorStatus
        ? `${customTag1.cl2.sensorAlias} (${customTag1.cl2.sensorUnit})`
        : ""
      : "Current 2",
    customTag1 && !isEmpty(customTag1) && customTag1.cl3
      ? customTag1.cl3.sensorStatus
        ? `${customTag1.cl3.sensorAlias} (${customTag1.cl3.sensorUnit})`
        : ""
      : "Current 3",
    customTag1 && !isEmpty(customTag1) && customTag1.kvar
      ? customTag1.kvar.sensorStatus
        ? `${customTag1.kvar.sensorAlias} (${customTag1.kvar.sensorUnit})`
        : ""
      : "Reactive Power",
    customTag1 && !isEmpty(customTag1) && customTag1.a_cl
      ? customTag1.a_cl.sensorStatus
        ? `${customTag1.a_cl.sensorAlias} (${customTag1.a_cl.sensorUnit})`
        : ""
      : "Average Current",
    customTag1 && !isEmpty(customTag1) && customTag1.pf1
      ? customTag1.pf1.sensorStatus
        ? `${customTag1.pf1.sensorAlias} (${customTag1.pf1.sensorUnit})`
        : ""
      : "Power Factor 1",
    customTag1 && !isEmpty(customTag1) && customTag1.pf2
      ? customTag1.pf2.sensorStatus
        ? `${customTag1.pf2.sensorAlias} (${customTag1.pf2.sensorUnit})`
        : ""
      : "Power Factor 2",
    customTag1 && !isEmpty(customTag1) && customTag1.pf3
      ? customTag1.pf3.sensorStatus
        ? `${customTag1.pf3.sensorAlias} (${customTag1.pf3.sensorUnit})`
        : ""
      : "Power Factor 3",
    customTag1 && !isEmpty(customTag1) && customTag1.a_pf
      ? customTag1.a_pf.sensorStatus
        ? `${customTag1.a_pf.sensorAlias} (${customTag1.a_pf.sensorUnit})`
        : ""
      : "Average Power Factor",
    customTag1 && !isEmpty(customTag1) && customTag1.kw1
      ? customTag1.kw1.sensorStatus
        ? `${customTag1.kw1.sensorAlias} (${customTag1.kw1.sensorUnit})`
        : ""
      : "Active Power 1",
    customTag1 && !isEmpty(customTag1) && customTag1.kw2
      ? customTag1.kw2.sensorStatus
        ? `${customTag1.kw2.sensorAlias} (${customTag1.kw2.sensorUnit})`
        : ""
      : "Active Power 2",
    customTag1 && !isEmpty(customTag1) && customTag1.kw3
      ? customTag1.kw3.sensorStatus
        ? `${customTag1.kw3.sensorAlias} (${customTag1.kw3.sensorUnit})`
        : ""
      : "Active Power 3",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kw
      ? customTag1.t_kw.sensorStatus
        ? `${customTag1.t_kw.sensorAlias} (${customTag1.t_kw.sensorUnit})`
        : ""
      : "Total Active Power",
    customTag1 && !isEmpty(customTag1) && customTag1.kv1
      ? customTag1.kv1.sensorStatus
        ? `${customTag1.kv1.sensorAlias} (${customTag1.kv1.sensorUnit})`
        : ""
      : "Reactive Power 1",
    customTag1 && !isEmpty(customTag1) && customTag1.kv2
      ? customTag1.kv2.sensorStatus
        ? `${customTag1.kv2.sensorAlias} (${customTag1.kv2.sensorUnit})`
        : ""
      : "Reactive Power 2",
    customTag1 && !isEmpty(customTag1) && customTag1.kv3
      ? customTag1.kv3.sensorStatus
        ? `${customTag1.kv3.sensorAlias} (${customTag1.kv3.sensorUnit})`
        : ""
      : "Reactive Power 3",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kv
      ? customTag1.t_kv.sensorStatus
        ? `${customTag1.t_kv.sensorAlias} (${customTag1.t_kv.sensorUnit})`
        : ""
      : "Total Reactive Power",
    customTag1 && !isEmpty(customTag1) && customTag1.kva1
      ? customTag1.kva1.sensorStatus
        ? `${customTag1.kva1.sensorAlias} (${customTag1.kva1.sensorUnit})`
        : ""
      : "Apparent Power 1",
    customTag1 && !isEmpty(customTag1) && customTag1.kva2
      ? customTag1.kva2.sensorStatus
        ? `${customTag1.kva2.sensorAlias} (${customTag1.kva2.sensorUnit})`
        : ""
      : "Apparent Power 2",
    customTag1 && !isEmpty(customTag1) && customTag1.kva3
      ? customTag1.kva3.sensorStatus
        ? `${customTag1.kva3.sensorAlias} (${customTag1.kva3.sensorUnit})`
        : ""
      : "Apparent Power 3",
    customTag1 && !isEmpty(customTag1) && customTag1.t_kva
      ? customTag1.t_kva.sensorStatus
        ? `${customTag1.t_kva.sensorAlias} (${customTag1.t_kva.sensorUnit})`
        : ""
      : "Total Apparent Power",
    customTag1 && !isEmpty(customTag1) && customTag1.freq
      ? customTag1.freq.sensorStatus
        ? `${customTag1.freq.sensorAlias} (${customTag1.freq.sensorUnit})`
        : ""
      : "Frequency",
  ]


  const energySinglePhase: any = dummyFilterData1.filter(
    (item: any) => item !== ""
  );

  const awfisEnergyData: any = dummyFilterData2.filter(
    (item: any) => item !== ""
  );

  useEffect(() => {
    if (deviceTemplate && deviceTemplate.tagJson) {
      const newCustomTag: any = {};
      deviceTemplate.tagJson.forEach((tag: any) => {
        newCustomTag[tag.sensorName] = { ...tag };
      });
      setCustomTag(newCustomTag);
    }
  }, [deviceTemplate]);
  function readRoles(token: any) {
    try {
      const decodedToken: any = jwtDecode<JwtPayload>(token);
      return decodedToken;
    } catch (error) {
      console.error(error);
    }
  }
  const role = readRoles(token);
  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  function getCustomTagValue(tagName: any, d: any, headerName: any) {
    if (customTag1 && !isEmpty(customTag1) && customTag1[tagName]) {
      const metric = customTag1[tagName];

      return { [`${metric.sensorAlias} (${metric.sensorUnit})`]: d[tagName] };
    } else {
      return { [headerName]: d[tagName] };
    }
  }

  // Function to handle click on a single checkbox
  const handleClick = (id: any) => {
    const index = selected.findIndex((item) => item === id);
    setSelected((prevSelected: any) =>
      index === -1
        ? [...prevSelected, id]
        : prevSelected.filter((item: any) => item !== id)
    );
  };

  useEffect(() => {
    fetchDeviceById(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (!device) return;
    // const device: any = devices.devices.find((d: any) => d.id === deviceId);
    setTemplate(device?.template);
    setDeviceName(device?.deviceName);
    const newHeaders = ["#", "TimeStamp"];
    if (device?.template === 5) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Flow",
        "Flow unit",
        "Total",
        "Total Unit",
      ];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device?.template === 7) {
      const baseHeaders = ["#", "TimeStamp", "Do", "Ph", "Tds", "Temp"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (
      device?.template === 8 ||
      device?.template === 23 ||
      device?.template === 22
    ) {
      const baseHeaders = ["#", "Timestamp", "X", "Y", "Z"];
      if (role.role === 1) {
        setHeaders(...baseHeaders, "RSSI");
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 9) {
      const baseHeaders = ["#", "Timestamp", "Totalizer", "Unit"];
      if (role.role === 1) {
        setHeaders(...baseHeaders, "RSSI");
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 20) {
      const baseHeaders = ["#", "Timestamp", "Temperature", "Humidity"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 10) {
      const baseHeaders = ["#", "Timestamp", "Totalizer", "Unit"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 12) {
      const baseHeaders = ["#", "TimeStamp", "Ni", "Ph", "Tds", "Temp"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 13) {
      const baseHeaders = ["#", "TimeStamp", "Cl", "Ph", "Tds", "Temp", "Turb"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 14) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "AverageVoltage",
        "AverageCurrent",
        "TotalActivePower",
        "TotalReactivePower",
        "TotalApparentPower",
        "Frequency",
        "RPhaseVoltage",
        "YPhaseVoltage",
        "YBPhaseVoltage",
        "BPhaseVoltage",
        "RYPhaseVoltage",
        "RBPhaseVoltage",
        "RPhaseCurrent",
        "YPhaseCurrent",
        "BPhaseCurrent",
        "PowerFactor",
        "RPhasePowerFactor",
        "YPhasePowerFactor",
        "BPhasePowerFactor",
        "RPhaseActivePower",
        "YPhaseActivePower",
        "BPhaseActivePower",
        "RPhaseReactivePower",
        "YPhaseReactivePower",
        "BPhaseReactivePower",
        "RPhaseApparentPower",
        "YPhaseApparentPower",
        "BPhaseApparentPower",
        "ActiveEnergy",
        "ReactiveEnergy",
        "ApparentEnergy",
        "yvtgphthd",
        "bvtgphthd",
        "rcrtphthd",
        "ycrtphthd",
        "bcrtphthd",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }

      // setOptionColumns(enery);
    } else if (device.template === 15) {
      if (deviceTemplate === null || customTag?.Flow?.sensorStatus !== false) {
        newHeaders.push("Flow", "Flow Unit");
      }
      if (
        deviceTemplate === null ||
        customTag?.Totalizer?.sensorStatus !== false
      ) {
        newHeaders.push("Totalizer", "Totalizer Unit");
      }
      setHeaders(newHeaders);
    } else if (device.template === 16) {
      const baseHeaders = ["#", "TimeStamp", "Totalizer", "Flow", "PumpStatus"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 17) {
      const baseHeaders = ["#", "TimeStamp", "Ph", "Temp"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 18) {
      const baseHeaders = ["#", "TimeStamp", "Tds", "Temp"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 19) {
      const baseHeaders = ["#", "TimeStamp", "Cl", "Turb", "Temp"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 11) {
      const baseHeaders = ["#", "TimeStamp", "Pump_1", "Pump_2"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 21) {
      const baseHeaders = ["#", "TimeStamp", "DeviceStatus"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 24) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "flow",
        "flowunit",
        "totalizer",
        "totalizerunit",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 27) {
      const baseHeaders = [
        "#",
        "Timestamp",
        "Flow",
        "Flowrate",
        "Totalizer",
        "Forward Totalizer",
        "Reverse Totalizer",
        "Battery Level",
        "Unit",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 25) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Energy_Generation",
        "Energy_Consumption",
        "Fuel_Consumed",
        "SFC",
        "Total_Run_Hours",
        "Active_Power",
        "Voltage",
        "Current",
        "Power_Factor",
        "Dg_Controller",
        "Tank_Level",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 6) {
      const baseHeaders = ["#", "TimeStamp", "Level"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 26) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Mppt_Power",
        "Mppt_Voltage",
        "Mppt_Current",
        "Ac_Current",
        "Ac_Voltage",
        "String_Voltage",
        "String_Current",
        "Inverter_Ambient",
        "Temperature_Inverter_Internal",
        "Inverter_Status",
        "Today_Energy",
        "Total_Energy",
        "Active_Power",
        "Power_Factor",
        "Efficiency",
        "CUF",
        "Battery_Level",
        "Pr_Ratio",
        "Frequency",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 28) {
      const baseHeaders = ["#", "TimeStamp", "Temperature"];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 29) {
      const baseHeaders = ["#", "TimeStamp", "Level", "Unit"];
      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 31) {
      const baseHeaders = ["#", "TimeStamp", "Totalizer", "Unit"];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 32) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Flow",
        "FlowUnit",
        "Total",
        "TotalUnit",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 42) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Temperature (°C)",
        "Humidity (%)",
        "Set Temp (°C)",
        "Status",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    }else if (device.template === 43) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Temperature (°C)",
        "Humidity (%)",
        "Set Temp (°C)",
        "Status",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } 
    else if (device.template === 37) {
      if (role.role === 1) {
        const dummyHeaders = [
          "#",
          "TimeStamp",
          customTag1 && !isEmpty(customTag1) && customTag1.Flow
            ? customTag1.Flow.sensorStatus
              ? `${customTag1.Flow.sensorAlias} (${customTag1.Flow.sensorUnit})`
              : ""
            : "Flow (m³/hr)",
          customTag1 && !isEmpty(customTag1) && customTag1.Totalizer
            ? customTag1.Totalizer.sensorStatus
              ? `${customTag1.Totalizer.sensorAlias} (${customTag1.Totalizer.sensorUnit})`
              : ""
            : "Totalizer (m³)",
          device.batteryConfigStatus ? "Battery (%)" : "",
          "Pulse",
          "Battery Voltage(V)",
          "RSSI",
        ];
        const filteredArray = dummyHeaders.filter((item) => item !== "");
        setHeaders(filteredArray);
      } else {
        const dummyHeaders = [
          "#",
          "TimeStamp",
          customTag1 && !isEmpty(customTag1) && customTag1.Flow
            ? customTag1.Flow.sensorStatus
              ? `${customTag1.Flow.sensorAlias} (${customTag1.Flow.sensorUnit})`
              : ""
            : "Flow (m³/hr)",
          customTag1 && !isEmpty(customTag1) && customTag1.Totalizer
            ? customTag1.Totalizer.sensorStatus
              ? `${customTag1.Totalizer.sensorAlias} (${customTag1.Totalizer.sensorUnit})`
              : ""
            : "Totalizer (m³)",
          device.batteryConfigStatus ? "Battery (%)" : "",
        ];
        const filteredArray = dummyHeaders.filter((item) => item !== "");
        setHeaders(filteredArray);
      }
    } else if (device.template === 33) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Flow",
        "FlowUnit",
        "Totalizer",
        "TotalizerUnit",
        "Battery",
        "BatteryUnit",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI", "Battery Voltage(V)"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 34||device.template === 49) {
      const baseHeaders = [
        "#",
        "TimeStamp",
        "Flow",
        "FlowUnit",
        "Totalizer",
        "TotalizerUnit",
      ];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 35) {
      const baseHeaders = ["#", "TimeStamp", "Level", "Unit"];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI", "Mass Level"]);
      } else {
        setHeaders(baseHeaders);
      }
    }else if (device.template === 47) {
      const baseHeaders = ["#", "TimeStamp", "Level", "Unit"];

      if (role.role === 1) {
        setHeaders([...baseHeaders, "RSSI"]);
      } else {
        setHeaders(baseHeaders);
      }
    } else if (device.template === 38) {
      const dummyHeaders = [
        "#",
        "TimeStamp",
        customTag1 && !isEmpty(customTag1) && customTag1.t_acte
          ? customTag1.t_acte.sensorStatus
            ? `${customTag1.t_acte.sensorAlias} (${customTag1.t_acte.sensorUnit})`
            : ""
          : "Active Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.t_re
          ? customTag1.t_re.sensorStatus
            ? `${customTag1.t_re.sensorAlias} (${customTag1.t_re.sensorUnit})`
            : ""
          : "Reactive Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.t_apte
          ? customTag1.t_apte.sensorStatus
            ? `${customTag1.t_apte.sensorAlias} (${customTag1.t_apte.sensorUnit})`
            : ""
          : "Apparent Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.imp_ae
          ? customTag1.imp_ae.sensorStatus
            ? `${customTag1.imp_ae.sensorAlias} (${customTag1.imp_ae.sensorUnit})`
            : ""
          : "Import Active",
        customTag1 && !isEmpty(customTag1) && customTag1.imp_re
          ? customTag1.imp_re.sensorStatus
            ? `${customTag1.imp_re.sensorAlias} (${customTag1.imp_re.sensorUnit})`
            : ""
          : "Import Reactive",
        customTag1 && !isEmpty(customTag1) && customTag1.exp_ae
          ? customTag1.exp_ae.sensorStatus
            ? `${customTag1.exp_ae.sensorAlias} (${customTag1.exp_ae.sensorUnit})`
            : ""
          : "Export Active",
        customTag1 && !isEmpty(customTag1) && customTag1.exp_re
          ? customTag1.exp_re.sensorStatus
            ? `${customTag1.exp_re.sensorAlias} (${customTag1.exp_re.sensorUnit})`
            : ""
          : "Export Reactive",
        customTag1 && !isEmpty(customTag1) && customTag1.av_ln
          ? customTag1.av_ln.sensorStatus
            ? `${customTag1.av_ln.sensorAlias} (${customTag1.av_ln.sensorUnit})`
            : ""
          : "Average Neutral",
        customTag1 && !isEmpty(customTag1) && customTag1.v1n
          ? customTag1.v1n.sensorStatus
            ? `${customTag1.v1n.sensorAlias} (${customTag1.v1n.sensorUnit})`
            : ""
          : "R Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.v2n
          ? customTag1.v2n.sensorStatus
            ? `${customTag1.v2n.sensorAlias} (${customTag1.v2n.sensorUnit})`
            : ""
          : "Y Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.v3n
          ? customTag1.v3n.sensorStatus
            ? `${customTag1.v3n.sensorAlias} (${customTag1.v3n.sensorUnit})`
            : ""
          : "B Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.av_ll
          ? customTag1.av_ll.sensorStatus
            ? `${customTag1.av_ll.sensorAlias} (${customTag1.av_ll.sensorUnit})`
            : ""
          : "Average Line-Line",
        customTag1 && !isEmpty(customTag1) && customTag1.v12
          ? customTag1.v12.sensorStatus
            ? `${customTag1.v12.sensorAlias} (${customTag1.v12.sensorUnit})`
            : ""
          : "RY Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.v23
          ? customTag1.v23.sensorStatus
            ? `${customTag1.v23.sensorAlias} (${customTag1.v23.sensorUnit})`
            : ""
          : "YB Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.v31
          ? customTag1.v31.sensorStatus
            ? `${customTag1.v31.sensorAlias} (${customTag1.v31.sensorUnit})`
            : ""
          : "RB Phase(V)",
        customTag1 && !isEmpty(customTag1) && customTag1.av_c
          ? customTag1.av_c.sensorStatus
            ? `${customTag1.av_c.sensorAlias} (${customTag1.av_c.sensorUnit})`
            : ""
          : "Average Current",
        customTag1 && !isEmpty(customTag1) && customTag1.cl1
          ? customTag1.cl1.sensorStatus
            ? `${customTag1.cl1.sensorAlias} (${customTag1.cl1.sensorUnit})`
            : ""
          : "R Phase(A)",
        customTag1 && !isEmpty(customTag1) && customTag1.cl2
          ? customTag1.cl2.sensorStatus
            ? `${customTag1.cl2.sensorAlias} (${customTag1.cl2.sensorUnit})`
            : ""
          : "Y Phase(A)",
        customTag1 && !isEmpty(customTag1) && customTag1.cl3
          ? customTag1.cl3.sensorStatus
            ? `${customTag1.cl3.sensorAlias} (${customTag1.cl3.sensorUnit})`
            : ""
          : "B Phase(A)",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kw
          ? customTag1.t_kw.sensorStatus
            ? `${customTag1.t_kw.sensorAlias} (${customTag1.t_kw.sensorUnit})`
            : ""
          : "Total Active",
        customTag1 && !isEmpty(customTag1) && customTag1.kw1
          ? customTag1.kw1.sensorStatus
            ? `${customTag1.kw1.sensorAlias} (${customTag1.kw1.sensorUnit})`
            : ""
          : "R Phase(kW)",
        customTag1 && !isEmpty(customTag1) && customTag1.kw2
          ? customTag1.kw2.sensorStatus
            ? `${customTag1.kw2.sensorAlias} (${customTag1.kw2.sensorUnit})`
            : ""
          : "Y Phase(kW)",
        customTag1 && !isEmpty(customTag1) && customTag1.kw3
          ? customTag1.kw3.sensorStatus
            ? `${customTag1.kw3.sensorAlias} (${customTag1.kw3.sensorUnit})`
            : ""
          : "B Phase(kW)",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kvar
          ? customTag1.t_kvar.sensorStatus
            ? `${customTag1.t_kvar.sensorAlias} (${customTag1.t_kvar.sensorUnit})`
            : ""
          : "Total Reactive",
        customTag1 && !isEmpty(customTag1) && customTag1.kvar1
          ? customTag1.kvar1.sensorStatus
            ? `${customTag1.kvar1.sensorAlias} (${customTag1.kvar1.sensorUnit})`
            : ""
          : "R Phase(kVAr)",
        customTag1 && !isEmpty(customTag1) && customTag1.kvar2
          ? customTag1.kvar2.sensorStatus
            ? `${customTag1.kvar2.sensorAlias} (${customTag1.kvar2.sensorUnit})`
            : ""
          : "Y Phase(kVAr)",
        customTag1 && !isEmpty(customTag1) && customTag1.kvar3
          ? customTag1.kvar3.sensorStatus
            ? `${customTag1.kvar3.sensorAlias} (${customTag1.kvar3.sensorUnit})`
            : ""
          : "B Phase(kVAr)",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kva
          ? customTag1.t_kva.sensorStatus
            ? `${customTag1.t_kva.sensorAlias} (${customTag1.t_kva.sensorUnit})`
            : ""
          : "Total Apparent",
        customTag1 && !isEmpty(customTag1) && customTag1.kva1
          ? customTag1.kva1.sensorStatus
            ? `${customTag1.kva1.sensorAlias} (${customTag1.kva1.sensorUnit})`
            : ""
          : "R Phase(kVa)",
        customTag1 && !isEmpty(customTag1) && customTag1.kva2
          ? customTag1.kva2.sensorStatus
            ? `${customTag1.kva2.sensorAlias} (${customTag1.kva2.sensorUnit})`
            : ""
          : "Y Phase(kVa)",
        customTag1 && !isEmpty(customTag1) && customTag1.kva3
          ? customTag1.kva3.sensorStatus
            ? `${customTag1.kva3.sensorAlias} (${customTag1.kva3.sensorUnit})`
            : ""
          : "B Phase(kVa)",
        customTag1 && !isEmpty(customTag1) && customTag1.av_pf
          ? customTag1.av_pf.sensorStatus
            ? `${customTag1.av_pf.sensorAlias} (${customTag1.av_pf.sensorUnit})`
            : ""
          : "Average PF",
        customTag1 && !isEmpty(customTag1) && customTag1.pf1
          ? customTag1.pf1.sensorStatus
            ? `${customTag1.pf1.sensorAlias} (${customTag1.pf1.sensorUnit})`
            : ""
          : "R Phase",
        customTag1 && !isEmpty(customTag1) && customTag1.pf2
          ? customTag1.pf2.sensorStatus
            ? `${customTag1.pf2.sensorAlias} (${customTag1.pf2.sensorUnit})`
            : ""
          : "Y Phase",
        customTag1 && !isEmpty(customTag1) && customTag1.pf3
          ? customTag1.pf3.sensorStatus
            ? `${customTag1.pf3.sensorAlias} (${customTag1.pf3.sensorUnit})`
            : ""
          : "B Phase",
        customTag1 && !isEmpty(customTag1) && customTag1.freq
          ? customTag1.freq.sensorStatus
            ? `${customTag1.freq.sensorAlias} (${customTag1.freq.sensorUnit})`
            : ""
          : "Frequency",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v1n
          ? customTag1.thd_v1n.sensorStatus
            ? `${customTag1.thd_v1n.sensorAlias} (${customTag1.thd_v1n.sensorUnit})`
            : ""
          : "R Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v2n
          ? customTag1.thd_v2n.sensorStatus
            ? `${customTag1.thd_v2n.sensorAlias} (${customTag1.thd_v2n.sensorUnit})`
            : ""
          : "Y Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v3n
          ? customTag1.thd_v3n.sensorStatus
            ? `${customTag1.thd_v3n.sensorAlias} (${customTag1.thd_v3n.sensorUnit})`
            : ""
          : "B Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v12
          ? customTag1.thd_v12.sensorStatus
            ? `${customTag1.thd_v12.sensorAlias} (${customTag1.thd_v12.sensorUnit})`
            : ""
          : "RY Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v23
          ? customTag1.thd_v23.sensorStatus
            ? `${customTag1.thd_v23.sensorAlias} (${customTag1.thd_v23.sensorUnit})`
            : ""
          : "YB Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_v31
          ? customTag1.thd_v31.sensorStatus
            ? `${customTag1.thd_v31.sensorAlias} (${customTag1.thd_v31.sensorUnit})`
            : ""
          : "RB Phase Volt(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_ci1
          ? customTag1.thd_ci1.sensorStatus
            ? `${customTag1.thd_ci1.sensorAlias} (${customTag1.thd_ci1.sensorUnit})`
            : ""
          : "R Phase Curr(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_ci2
          ? customTag1.thd_ci2.sensorStatus
            ? `${customTag1.thd_ci2.sensorAlias} (${customTag1.thd_ci2.sensorUnit})`
            : ""
          : "Y Phase Curr(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.thd_ci3
          ? customTag1.thd_ci3.sensorStatus
            ? `${customTag1.thd_ci3.sensorAlias} (${customTag1.thd_ci3.sensorUnit})`
            : ""
          : "B Phase Curr(%)",
        customTag1 && !isEmpty(customTag1) && customTag1.ap_maxd
          ? customTag1.ap_maxd.sensorStatus
            ? `${customTag1.ap_maxd.sensorAlias} (${customTag1.ap_maxd.sensorUnit})`
            : ""
          : "Max Active Power",
        customTag1 && !isEmpty(customTag1) && customTag1.ap_mind
          ? customTag1.ap_mind.sensorStatus
            ? `${customTag1.ap_mind.sensorAlias} (${customTag1.ap_mind.sensorUnit})`
            : ""
          : "Min Active Power",
        customTag1 && !isEmpty(customTag1) && customTag1.rp_maxd
          ? customTag1.rp_maxd.sensorStatus
            ? `${customTag1.rp_maxd.sensorAlias} (${customTag1.rp_maxd.sensorUnit})`
            : ""
          : "Max Reactive Power",
        customTag1 && !isEmpty(customTag1) && customTag1.rp_mind
          ? customTag1.rp_mind.sensorStatus
            ? `${customTag1.rp_mind.sensorAlias} (${customTag1.rp_mind.sensorUnit})`
            : ""
          : "Min Reactive Power",
        customTag1 && !isEmpty(customTag1) && customTag1.apr_maxd
          ? customTag1.apr_maxd.sensorStatus
            ? `${customTag1.apr_maxd.sensorAlias} (${customTag1.apr_maxd.sensorUnit})`
            : ""
          : "Max Apparent Power",
        customTag1 && !isEmpty(customTag1) && customTag1.r_hour
          ? customTag1.r_hour.sensorStatus
            ? `${customTag1.r_hour.sensorAlias} (${customTag1.r_hour.sensorUnit})`
            : ""
          : "Run Hours",
        customTag1 && !isEmpty(customTag1) && customTag1.a_intrpt
          ? customTag1.a_intrpt.sensorStatus
            ? `${customTag1.a_intrpt.sensorAlias} (${customTag1.a_intrpt.sensorUnit})`
            : ""
          : "Current Interrupt",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv1n
          ? customTag1.max_iv1n.sensorStatus
            ? `${customTag1.max_iv1n.sensorAlias} (${customTag1.max_iv1n.sensorUnit})`
            : ""
          : "R Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv2n
          ? customTag1.max_iv2n.sensorStatus
            ? `${customTag1.max_iv2n.sensorAlias} (${customTag1.max_iv2n.sensorUnit})`
            : ""
          : "Y Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv3n
          ? customTag1.max_iv3n.sensorStatus
            ? `${customTag1.max_iv3n.sensorAlias} (${customTag1.max_iv3n.sensorUnit})`
            : ""
          : "B Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv12
          ? customTag1.max_iv12.sensorStatus
            ? `${customTag1.max_iv12.sensorAlias} (${customTag1.max_iv12.sensorUnit})`
            : ""
          : "RY Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv23
          ? customTag1.max_iv23.sensorStatus
            ? `${customTag1.max_iv23.sensorAlias} (${customTag1.max_iv23.sensorUnit})`
            : ""
          : "YB Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_iv31
          ? customTag1.max_iv31.sensorStatus
            ? `${customTag1.max_iv31.sensorAlias} (${customTag1.max_iv31.sensorUnit})`
            : ""
          : "RB Phase Volt(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_ici1
          ? customTag1.max_ici1.sensorStatus
            ? `${customTag1.max_ici1.sensorAlias} (${customTag1.max_ici1.sensorUnit})`
            : ""
          : "R Phase Curr(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_ici2
          ? customTag1.max_ici2.sensorStatus
            ? `${customTag1.max_ici2.sensorAlias} (${customTag1.max_ici2.sensorUnit})`
            : ""
          : "Y Phase Curr(max A)",
        customTag1 && !isEmpty(customTag1) && customTag1.max_ici3
          ? customTag1.max_ici3.sensorStatus
            ? `${customTag1.max_ici3.sensorAlias} (${customTag1.max_ici3.sensorUnit})`
            : ""
          : "B Phase Curr(max A)",
      ];
      const filteredArray = dummyHeaders.filter((item) => item !== "");
      if (role.role === 1) {
        setHeaders([...filteredArray, "RSSI"]);
      } else {
        setHeaders(filteredArray);
      }
    }else if (device.template === 45) {
      const baseHeaders = ["#", "TimeStamp",
        customTag1 && !isEmpty(customTag1) && customTag1.tag1
      ? customTag1.tag1.sensorStatus
        ? `${customTag1.tag1.sensorAlias} (${customTag1.tag1.sensorUnit})`
        : ""
      : "Tag 1",
      customTag1 && !isEmpty(customTag1) && customTag1.tag2
      ? customTag1.tag2.sensorStatus
        ? `${customTag1.tag2.sensorAlias} (${customTag1.tag2.sensorUnit})`
        : ""
      : "Tag 2",
      customTag1 && !isEmpty(customTag1) && customTag1.tag3
      ? customTag1.tag3.sensorStatus
        ? `${customTag1.tag3.sensorAlias} (${customTag1.tag3.sensorUnit})`
        : ""
      : "Tag 3",
      customTag1 && !isEmpty(customTag1) && customTag1.tag4
      ? customTag1.tag4.sensorStatus
        ? `${customTag1.tag4.sensorAlias} (${customTag1.tag4.sensorUnit})`
        : ""
      : "Tag 4",
      ];
      const filteredArray = baseHeaders.filter((item) => item !== "");

      if (role.role === 1) {
        setHeaders([...filteredArray, "RSSI"]);
      } else {
        setHeaders(filteredArray);
      }
    } else if (device.template === 41) {
      const dummyHeaders = [
        "#",
        "TimeStamp",
        customTag1 && !isEmpty(customTag1) && customTag1.t_acte
          ? customTag1.t_acte.sensorStatus
            ? `${customTag1.t_acte.sensorAlias} (${customTag1.t_acte.sensorUnit})`
            : ""
          : "Active Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.t_re
          ? customTag1.t_re.sensorStatus
            ? `${customTag1.t_re.sensorAlias} (${customTag1.t_re.sensorUnit})`
            : ""
          : "Reactive Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.t_apte
          ? customTag1.t_apte.sensorStatus
            ? `${customTag1.t_apte.sensorAlias} (${customTag1.t_apte.sensorUnit})`
            : ""
          : "Apparent Energy",
        customTag1 && !isEmpty(customTag1) && customTag1.r_hr
          ? customTag1.r_hr.sensorStatus
            ? `${customTag1.r_hr.sensorAlias} (${customTag1.r_hr.sensorUnit})`
            : ""
          : "Run Hours",
        customTag1 && !isEmpty(customTag1) && customTag1.v
          ? customTag1.v.sensorStatus
            ? `${customTag1.v.sensorAlias} (${customTag1.v.sensorUnit})`
            : ""
          : "Voltage",
        customTag1 && !isEmpty(customTag1) && customTag1.a
          ? customTag1.a.sensorStatus
            ? `${customTag1.a.sensorAlias} (${customTag1.a.sensorUnit})`
            : ""
          : "Current",
        customTag1 && !isEmpty(customTag1) && customTag1.kW
          ? customTag1.kW.sensorStatus
            ? `${customTag1.kW.sensorAlias} (${customTag1.kW.sensorUnit})`
            : ""
          : "Active Power",
        customTag1 && !isEmpty(customTag1) && customTag1.kvar
          ? customTag1.kvar.sensorStatus
            ? `${customTag1.kvar.sensorAlias} (${customTag1.kvar.sensorUnit})`
            : ""
          : "Reactive Power",
        customTag1 && !isEmpty(customTag1) && customTag1.kva
          ? customTag1.kva.sensorStatus
            ? `${customTag1.kva.sensorAlias} (${customTag1.kva.sensorUnit})`
            : ""
          : "Apparent Power",
        customTag1 && !isEmpty(customTag1) && customTag1.hz
          ? customTag1.hz.sensorStatus
            ? `${customTag1.hz.sensorAlias} (${customTag1.hz.sensorUnit})`
            : ""
          : "Frequncy",
        customTag1 && !isEmpty(customTag1) && customTag1.pf
          ? customTag1.pf.sensorStatus
            ? `${customTag1.pf.sensorAlias} (${customTag1.pf.sensorUnit})`
            : ""
          : "Power Factor",
        customTag1 && !isEmpty(customTag1) && customTag1.ex1
          ? customTag1.ex1.sensorStatus
            ? `${customTag1.ex1.sensorAlias} (${customTag1.ex1.sensorUnit})`
            : ""
          : "Extra 1",
        customTag1 && !isEmpty(customTag1) && customTag1.ex2
          ? customTag1.ex2.sensorStatus
            ? `${customTag1.ex2.sensorAlias} (${customTag1.ex2.sensorUnit})`
            : ""
          : "Extra 2",
        customTag1 && !isEmpty(customTag1) && customTag1.ex3
          ? customTag1.ex3.sensorStatus
            ? `${customTag1.ex3.sensorAlias} (${customTag1.ex3.sensorUnit})`
            : ""
          : "Extra 3",
        customTag1 && !isEmpty(customTag1) && customTag1.ex4
          ? customTag1.ex4.sensorStatus
            ? `${customTag1.ex4.sensorAlias} (${customTag1.ex4.sensorUnit})`
            : ""
          : "Extra 4",
        customTag1 && !isEmpty(customTag1) && customTag1.ex5
          ? customTag1.ex5.sensorStatus
            ? `${customTag1.ex5.sensorAlias} (${customTag1.ex5.sensorUnit})`
            : ""
          : "Extra 5",
      ];
      const filteredArray = dummyHeaders.filter((item) => item !== "");
      if (role.role === 1) {
        setHeaders([...filteredArray, "RSSI"]);
      } else {
        setHeaders(filteredArray);
      }
    }
    else if (device.template === 51) {
      const dummyHeaders = [
        "#",
        "TimeStamp",
        customTag1 && !isEmpty(customTag1) && customTag1.v1
          ? customTag1.v1.sensorStatus
            ? `${customTag1.v1.sensorAlias} (${customTag1.v1.sensorUnit})`
            : ""
          : "Voltage 1",
        customTag1 && !isEmpty(customTag1) && customTag1.v2
          ? customTag1.v2.sensorStatus
            ? `${customTag1.v2.sensorAlias} (${customTag1.v2.sensorUnit})`
            : ""
          : "Voltage 2",
        customTag1 && !isEmpty(customTag1) && customTag1.v3
          ? customTag1.v3.sensorStatus
            ? `${customTag1.v3.sensorAlias} (${customTag1.v3.sensorUnit})`
            : ""
          : "Voltage 3",
        customTag1 && !isEmpty(customTag1) && customTag1.a_v
          ? customTag1.a_v.sensorStatus
            ? `${customTag1.a_v.sensorAlias} (${customTag1.a_v.sensorUnit})`
            : ""
          : "Average Voltage",
        customTag1 && !isEmpty(customTag1) && customTag1.cl1
          ? customTag1.cl1.sensorStatus
            ? `${customTag1.cl1.sensorAlias} (${customTag1.cl1.sensorUnit})`
            : ""
          : "Current 1",
        customTag1 && !isEmpty(customTag1) && customTag1.cl2
          ? customTag1.cl2.sensorStatus
            ? `${customTag1.cl2.sensorAlias} (${customTag1.cl2.sensorUnit})`
            : ""
          : "Current 2",
        customTag1 && !isEmpty(customTag1) && customTag1.cl3
          ? customTag1.cl3.sensorStatus
            ? `${customTag1.cl3.sensorAlias} (${customTag1.cl3.sensorUnit})`
            : ""
          : "Current 3",
        customTag1 && !isEmpty(customTag1) && customTag1.a_cl
          ? customTag1.a_cl.sensorStatus
            ? `${customTag1.a_cl.sensorAlias} (${customTag1.a_cl.sensorUnit})`
            : ""
          : "Average Current",
        customTag1 && !isEmpty(customTag1) && customTag1.pf1
          ? customTag1.pf1.sensorStatus
            ? `${customTag1.pf1.sensorAlias} (${customTag1.pf1.sensorUnit})`
            : ""
          : "Power Factor 1",
        customTag1 && !isEmpty(customTag1) && customTag1.pf2
          ? customTag1.pf2.sensorStatus
            ? `${customTag1.pf2.sensorAlias} (${customTag1.pf2.sensorUnit})`
            : ""
          : "Power Factor 2",
        customTag1 && !isEmpty(customTag1) && customTag1.pf3
          ? customTag1.pf3.sensorStatus
            ? `${customTag1.pf3.sensorAlias} (${customTag1.pf3.sensorUnit})`
            : ""
          : "Power Factor 3",
        customTag1 && !isEmpty(customTag1) && customTag1.a_pf
          ? customTag1.a_pf.sensorStatus
            ? `${customTag1.a_pf.sensorAlias} (${customTag1.a_pf.sensorUnit})`
            : ""
          : "Average Power Factor",
        customTag1 && !isEmpty(customTag1) && customTag1.kw1
          ? customTag1.kw1.sensorStatus
            ? `${customTag1.kw1.sensorAlias} (${customTag1.kw1.sensorUnit})`
            : ""
          : "Active Power 1",
        customTag1 && !isEmpty(customTag1) && customTag1.kw2
          ? customTag1.kw2.sensorStatus
            ? `${customTag1.kw2.sensorAlias} (${customTag1.kw2.sensorUnit})`
            : ""
          : "Active Power 2",
        customTag1 && !isEmpty(customTag1) && customTag1.kw3
          ? customTag1.kw3.sensorStatus
            ? `${customTag1.kw3.sensorAlias} (${customTag1.kw3.sensorUnit})`
            : ""
          : "Active Power 3",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kw
          ? customTag1.t_kw.sensorStatus
            ? `${customTag1.t_kw.sensorAlias} (${customTag1.t_kw.sensorUnit})`
            : ""
          : "Total Active Power",
        customTag1 && !isEmpty(customTag1) && customTag1.kv1
          ? customTag1.kv1.sensorStatus
            ? `${customTag1.kv1.sensorAlias} (${customTag1.kv1.sensorUnit})`
            : ""
          : "Reactive Power 1",
        customTag1 && !isEmpty(customTag1) && customTag1.kv2
          ? customTag1.kv2.sensorStatus
            ? `${customTag1.kv2.sensorAlias} (${customTag1.kv2.sensorUnit})`
            : ""
          : "Reactive Power 2",
        customTag1 && !isEmpty(customTag1) && customTag1.kv3
          ? customTag1.kv3.sensorStatus
            ? `${customTag1.kv3.sensorAlias} (${customTag1.kv3.sensorUnit})`
            : ""
          : "Reactive Power 3",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kv
          ? customTag1.t_kv.sensorStatus
            ? `${customTag1.t_kv.sensorAlias} (${customTag1.t_kv.sensorUnit})`
            : ""
          : "Total Reactive Power",
        customTag1 && !isEmpty(customTag1) && customTag1.kva1
          ? customTag1.kva1.sensorStatus
            ? `${customTag1.kva1.sensorAlias} (${customTag1.kva1.sensorUnit})`
            : ""
          : "Apparent Power 1",
        customTag1 && !isEmpty(customTag1) && customTag1.kva2
          ? customTag1.kva2.sensorStatus
            ? `${customTag1.kva2.sensorAlias} (${customTag1.kva2.sensorUnit})`
            : ""
          : "Apparent Power 2",
        customTag1 && !isEmpty(customTag1) && customTag1.kva3
          ? customTag1.kva3.sensorStatus
            ? `${customTag1.kva3.sensorAlias} (${customTag1.kva3.sensorUnit})`
            : ""
          : "Apparent Power 3",
        customTag1 && !isEmpty(customTag1) && customTag1.t_kva
          ? customTag1.t_kva.sensorStatus
            ? `${customTag1.t_kva.sensorAlias} (${customTag1.t_kva.sensorUnit})`
            : ""
          : "Total Apparent Power",
        customTag1 && !isEmpty(customTag1) && customTag1.freq
          ? customTag1.freq.sensorStatus
            ? `${customTag1.freq.sensorAlias} (${customTag1.freq.sensorUnit})`
            : ""
          : "Frequency",
      ];
      const filteredArray = dummyHeaders.filter((item) => item !== "");
      if (role.role === 1) {
        setHeaders([...filteredArray, "RSSI"]);
      } else {
        setHeaders(filteredArray);
      }
    }
  }, [device]);

  const fetchDeviceById = async (deviceId: number) => {
    try {
      const current_device = (await http(`/device?id=${deviceId}`)) as any;
      setDevice(current_device.devices);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  const chartData = {
    xAxis: [{ data: [1, 2, 3, 5, 8, 10] }],
    series: [{ data: [2, 5.5, 2, 8.5, 1.5, 5] }],
  };

  const [startValue, setStartValue]: any = React.useState<Dayjs | null>(null);
  const [endValue, setEndValue]: any = React.useState<Dayjs | null>(null);
  const [selectedOption, setSelectedOption] = useState<"chart" | "table">(
    "table"
  );

  useEffect(() => {
    if (startValue && endValue) {
      const startDate = startValue.toDate();
      const endDate = endValue.toDate();

      if (!isNaN(startDate) && !isNaN(endDate)) {
        getData({ startDate, endDate, deviceId });
      }
    }
  }, [startValue, endValue, deviceId]);


  async function getData({ startDate, endDate, deviceId, page = 1, limit = 10 }: any) {
    setLoading(true);
    try {
      const response: any = await http(
        `/device-data?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&deviceId=${deviceId}&page=${page}&limit=${limit}`
      );
      setApiData1(response.data);
      setPagination(response)
      const resp = response.data
      let _data = [];
      if (template === 5) {
        _data = resp.map((d: any, i: any) => {
          if (+d["firmwareVersionId"] !== 1) {
            return {
              logId: d.logId,
              TimeStamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? d["Forward Total Flow"]
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? d["Reverse Total Flow"]
                  : "ERROR",

              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          } else {
            return {
              logId: d.logId,

              TimeStamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: calculateFlow(resp, i),
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
                  : "ERROR",
              // total:
              //   d["Differential Total Flow"] !== "ERROR"
              //     ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
              //     : "ERROR",
              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          }
        });
      } else if (template === 6) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            level: d["Piezo Level"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 7) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            do: d["DO"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 8 || template === 22 || template === 23) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            x: d["x"],
            y: d["y"],
            z: d["z"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 9) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            totalizer: d["Totalizer"],
            unit: "m³",
            RSSI: d["rssi"],
          };
        });
      } else if (template === 10) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 12) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            ni: d["NI"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 13) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
            turb: d["TURB"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 14) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            averagevoltage: d["avg_vtg"],
            averagecurrent: d["avg_crt"],
            totalactivepower: d["total_act_po"],
            totalreactivepower: d["total_react_po"],
            totalapparentpower: d["total_app_po"],
            frequency: d["frequency"],
            rphasevoltage: d["r_ph_vtg"],
            yphasevoltage: d["y_ph_vtg"],
            ybphasevoltage: d["yb_ph_vtg"],
            bphasevoltage: d["b_ph_vtg"],
            ryphasevoltage: d["ry_ph_vtg"],
            rbphasevoltage: d["rb_ph_vtg"],
            rphasecurrent: d["r_ph_crt"],
            yphasecurrent: d["y_ph_crt"],
            bphasecurrent: d["b_ph_crt"],
            powerfactor: d["power_factor"],
            rphasepowerfactor: d["r_ph_pf"],
            yphasepowerfactor: d["y_ph_pf"],
            bphasepowerfactor: d["b_ph_pf"],
            rphaseactivepower: d["r_ph_act_po"],
            yphaseactivepower: d["y_ph_act_po"],
            bphaseactivepower: d["b_ph_act_po"],
            rphasereactivepower: d["r_ph_react_po"],
            yphasereactivepower: d["y_ph_react_po"],
            bphasereactivepower: d["b_ph_react_po"],
            rphaseapparentpower: d["r_ph_app_po"],
            yphaseapparentpower: d["y_ph_app_po"],
            bphaseapparentpower: d["b_ph_app_po"],
            activeenergy: parseFloat(d["active_energy"]).toFixed(3),
            reactiveenergy: parseFloat(d["reactive_energy"]).toFixed(3),
            apparentenergy: parseFloat(d["apparent_energy"]).toFixed(3),

            rvtgphthd: d["r_vtg_ph_thd"],
            yvtgphthd: d["y_vtg_ph_thd"],
            bvtgphthd: d["b_vtg_ph_thd"],
            rcrtphthd: d["r_crt_ph_thd"],
            ycrtphthd: d["y_crt_ph_thd"],
            bcrtphthd: d["b_crt_ph_thd"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 15) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            "Flow unit": deviceTemplate ? customTag.Flow?.sensorUnit : "m³/hr",
            totalizer: d["Totalizer"],
            "Totalizer unit": deviceTemplate
              ? customTag.Totalizer?.sensorUnit
              : "m³",
          };
        });
      } else if (template === 16) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            totalizer: d["Totalizer"],
            pumpstatus: d["Pump Status"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 17) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            ph: d["PH"],
            temp: d["TEMP"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 18) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            tds: d["TDS"],
            temp: d["TEMP"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 19) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            turb: d["TURB"],
            temp: d["TEMP"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 11) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            pump_1: d["pump_1"],
            pump_2: d["pump_2"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 21) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            devicestatus: d["device_status"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 24) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            flow: d["Inst_flow_rate"],
            flowunit: "m³/hr",
            totalizer: +d["Pos_Cumu_flow_Int"] + +d["Pos_Cumu_flow_Dec"],
            totalizerunit: "m³",
            RSSI: d["rssi"],
          };
        });
      } else if (template === 20) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 27) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            Flow: d["Inst_flow"],
            Flowrate: d["Inst_flow_rate"],
            Totalizer: d["Cumu_total"],
            "Forward Totalizer": +d["Pos_Cumu_Int"] + +d["Pos_Cumu_Frac"],
            "Reverse Totalizer": d["Rev_Cumu_Dec"],
            "Battery Level": d["Batt_lvl"],
            unit: ["m\u00B3"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 25) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            energy_generation: d["ene_gener"],
            energy_consumption: d["ene_cons"],
            fuel_consumed: d["fuel_cons"],
            sfc: d["sfc"],
            total_run_hours: d["total_run_hr"],
            active_power: d["act_po"],
            voltage: d["vtg"],
            current: d["crt"],
            power_factor: d["pf"],
            dg_controller: d["dg_ctrl"],
            tank_level: d["tank_level"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 26) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            mppt_power: d["mppt_po"],
            mppt_voltage: d["mppt_vtg"],
            mppt_current: d["mppt_crt"],
            ac_current: d["ac_crt"],
            ac_voltage: d["ac_vtg"],
            string_voltage: d["str_vtg"],
            string_current: d["str_crt"],
            inverter_ambient: d["inver_temp_amb"],
            temperature_inverter_internal: d["inver_temp_intr"],
            inverter_status: d["inver_status"],
            today_energy: d["today_energy"],
            total_energy: d["total_energy"],
            active_power: d["act_po"],
            power_factor: d["pf"],
            efficiency: d["effic"],
            cuf: d["cuf"],
            battery_level: d["bat_level"],
            pr_ratio: d["pr_ratio"],
            frequency: d["frequency"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 28) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 29) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            Level: parseFloat(d["Level"]).toFixed(3),
            unit: " cm",
            RSSI: d["rssi"],
          };
        });
      } else if (template === 31) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            Totalizer: +d["Pos_Cumu_flow_Int"] + +d["Pos_Cumu_flow_Dec"],
            Unit: "m³",
            RSSI: d["rssi"],
          };
        });
      } else if (template === 32) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            flow: parseFloat(d["Flow"]),
            flowunit: "m³/hr",
            total: d["Total"],
            totalunit: "m³",
            RSSI: d["rssi"],
          };
        });
      } else if (template === 33) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            flow: d["Flow"] || 0,
            flowunit: "m³/hr",
            totalizer: d["Totalizer"] || 0,
            totalizerunit: "m³",
            battery: d["batteryValue"],
            batteryunit: "%",
            RSSI: d["rssi"],
            "Battery Voltage(V)": d["batt_V"]
          };
        });
      } else if (template === 37) {
        _data = resp.map((d: any) => {
          if (role.role === 1) {
            return {
              logId: d.logId,
              TimeStamp: formatTimestamp(d.timeStamp),
              ...getCustomTagValue("Flow", d, "Flow (m³/hr)"),
              ...getCustomTagValue("Totalizer", d, "Totalizer (m³)"),
              "Battery (%)": d["batteryValue"],
              pulse: d.pulse ? d.pulse : null,
              "Battery Voltage(V)": d["batt_V"],
              RSSI: d["rssi"],
            };
          } else {
            return {
              logId: d.logId,
              TimeStamp: formatTimestamp(d.timeStamp),
              ...getCustomTagValue("Flow", d, "Flow (m³/hr)"),
              ...getCustomTagValue("Totalizer", d, "Totalizer (m³)"),
              "Battery (%)": d["batteryValue"],
            };
          }
        });
      } else if (template === 34||template === 49) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
            flowunit: "m³/hr",
            totalizer: d["Totalizer"] !== "ERROR" ? d["Totalizer"] : "ERROR",
            totalizerunit: "m³",
            RSSI: Math.round((+d.rssi / 35) * 110),
          };
        });
      } else if (template === 35) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            Level: parseFloat(d["mass_level"]).toFixed(3),
            unit: ["m"],
            RSSI: d["rssi"],
            "Mass Level": d["mass_level"]
          };
        });
      }else if (template === 47) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            Level: parseFloat(d["Level"]).toFixed(3),
            unit: ["m"],
            RSSI: d["rssi"],
          };
        });
      } else if (template === 38) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            // analysis tab
            ...getCustomTagValue("t_acte", d, "Active Energy"),
            ...getCustomTagValue("t_re", d, "Reactive Energy"),
            ...getCustomTagValue("t_apte", d, "Apparent Energy"),
            ...getCustomTagValue("imp_ae", d, "Import Active"),
            ...getCustomTagValue("imp_re", d, "Import Reactive"),
            ...getCustomTagValue("exp_ae", d, "Export Active"),
            ...getCustomTagValue("exp_re", d, "Export Reactive"),

            // Voltage tab
            ...getCustomTagValue("av_ln", d, "Average Neutral"),
            ...getCustomTagValue("v1n", d, "R Phase(V)"),
            ...getCustomTagValue("v2n", d, "Y Phase(V)"),
            ...getCustomTagValue("v3n", d, "B Phase(V)"),
            ...getCustomTagValue("av_ll", d, "Average Line-Line"),
            ...getCustomTagValue("v12", d, "RY Phase(V)"),
            ...getCustomTagValue("v23", d, "YB Phase(V)"),
            ...getCustomTagValue("v31", d, "RB Phase(V)"),

            // Current Tab
            ...getCustomTagValue("av_c", d, "Average Current"),
            ...getCustomTagValue("cl1", d, "R Phase(A)"),
            ...getCustomTagValue("cl2", d, "Y Phase(A)"),
            ...getCustomTagValue("cl3", d, "B Phase(A)"),

            // Power Tab
            ...getCustomTagValue("t_kw", d, "Total Active"),
            ...getCustomTagValue("kw1", d, "R Phase(kW)"),
            ...getCustomTagValue("kw2", d, "Y Phase(kW)"),
            ...getCustomTagValue("kw3", d, "B Phase(kW)"),

            ...getCustomTagValue("t_kvar", d, "Total Reactive"),
            ...getCustomTagValue("kvar1", d, "R Phase(kVAr)"),
            ...getCustomTagValue("kvar2", d, "Y Phase(kVAr)"),
            ...getCustomTagValue("kvar3", d, "B Phase(kVAr)"),

            ...getCustomTagValue("t_kva", d, "Total Apparent"),
            ...getCustomTagValue("kva1", d, "R Phase(kVa)"),
            ...getCustomTagValue("kva2", d, "Y Phase(kVa)"),
            ...getCustomTagValue("kva3", d, "B Phase(kVa)"),

            // Power Factor Tab
            ...getCustomTagValue("av_pf", d, "Average PF"),
            ...getCustomTagValue("pf1", d, "R Phase"),
            ...getCustomTagValue("pf2", d, "Y Phase"),
            ...getCustomTagValue("pf3", d, "B Phase"),

            // Frequency Tab
            ...getCustomTagValue("freq", d, "Frequency"),

            // Treshold Tab
            ...getCustomTagValue("thd_v1n", d, "R Phase Volt(%)"),
            ...getCustomTagValue("thd_v2n", d, "Y Phase Volt(%)"),
            ...getCustomTagValue("thd_v3n", d, "B Phase Volt(%)"),

            ...getCustomTagValue("thd_v12", d, "RY Phase Volt(%)"),
            ...getCustomTagValue("thd_v23", d, "YB Phase Volt(%)"),
            ...getCustomTagValue("thd_v31", d, "RB Phase Volt(%)"),

            ...getCustomTagValue("thd_ci1", d, "R Phase Curr(%)"),
            ...getCustomTagValue("thd_ci2", d, "Y Phase Curr(%)"),
            ...getCustomTagValue("thd_ci3", d, "B Phase Curr(%)"),

            // Demand Tab
            ...getCustomTagValue("ap_maxd", d, "Max Active Power"),
            ...getCustomTagValue("ap_mind", d, "Min Active Power"),
            ...getCustomTagValue("rp_maxd", d, "Max Reactive Power"),
            ...getCustomTagValue("rp_mind", d, "Min Reactive Power"),
            ...getCustomTagValue("apr_maxd", d, "Max Apparent Power"),

            // Others Tab
            ...getCustomTagValue("r_hour", d, "Run Hours"),
            ...getCustomTagValue("a_intrpt", d, "Current Interrupt"),

            ...getCustomTagValue("max_iv1n", d, "R Phase Volt(max A)"),
            ...getCustomTagValue("max_iv2n", d, "Y Phase Volt(max A)"),
            ...getCustomTagValue("max_iv3n", d, "B Phase Volt(max A)"),

            ...getCustomTagValue("max_iv12", d, "RY Phase Volt(max A)"),
            ...getCustomTagValue("max_iv23", d, "YB Phase Volt(max A)"),
            ...getCustomTagValue("max_iv31", d, "RB Phase Volt(max A)"),

            ...getCustomTagValue("max_ici1", d, "R Phase Curr(max A)"),
            ...getCustomTagValue("max_ici2", d, "Y Phase Curr(max A)"),
            ...getCustomTagValue("max_ici3", d, "B Phase Curr(max A)"),
            RSSI: d["rssi"],
          };
        });
      } else if (template === 41) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            ...getCustomTagValue("t_acte", d, "Active Energy"),
            ...getCustomTagValue("t_re", d, "Reactive Energy"),
            ...getCustomTagValue("t_apte", d, "Apparent Energy"),
            ...getCustomTagValue("r_hr", d, "Run Hours"),

            ...getCustomTagValue("v", d, "Voltage"),
            ...getCustomTagValue("a", d, "Current"),
            ...getCustomTagValue("kW", d, "Active Power"),
            ...getCustomTagValue("kvar", d, "Reactive Power"),
            ...getCustomTagValue("kva", d, "Apparent Power"),
            ...getCustomTagValue("pf", d, "Power Factor"),
            ...getCustomTagValue("hz", d, "Frequncy"),
            ...getCustomTagValue("ex1", d, "Extra 1"),
            ...getCustomTagValue("ex2", d, "Extra 2"),
            ...getCustomTagValue("ex3", d, "Extra 3"),
            ...getCustomTagValue("ex4", d, "Extra 4"),
            ...getCustomTagValue("ex5", d, "Extra 5"),
            RSSI: d["rssi"],
          };
        });
      }else if (template === 45) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            ...getCustomTagValue("tag1", d, "Tag 1"),
            ...getCustomTagValue("tag2", d, "Tag 2"),
            ...getCustomTagValue("tag3", d, "Tag 3"),
            ...getCustomTagValue("tag4", d, "Tag 4"),
            RSSI: d["rssi"],
          };
        });
      }else if (template === 51) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            ...getCustomTagValue("v1", d, "Voltage 1"),
            ...getCustomTagValue("v2", d, "Voltage 2"),
            ...getCustomTagValue("v3", d, "Voltage 3"),
            ...getCustomTagValue("a_v", d, "Average Voltage"),

            ...getCustomTagValue("cl1", d, "Current 1"),
            ...getCustomTagValue("cl2", d, "Current 2"),
            ...getCustomTagValue("cl3", d, "Current 3"),
            ...getCustomTagValue("a_cl", d, "Average Current"),
            ...getCustomTagValue("pf1", d, "Power Factor 1"),
            ...getCustomTagValue("pf2", d, "Power Factor 2"),
            ...getCustomTagValue("pf3", d, "Power Factor 3"),
            ...getCustomTagValue("a_pf", d, "Average Power Factor"),
            ...getCustomTagValue("kw1", d, "Active Power 1"),
            ...getCustomTagValue("kw2", d, "Active Power 2"),
            ...getCustomTagValue("kw3", d, "Active Power 3"),
            ...getCustomTagValue("t_kw", d, "Total Active Power"),
            ...getCustomTagValue("kv1", d, "Reactive Power 1"),
            ...getCustomTagValue("kv2", d, "Reactive Power 2"),
            ...getCustomTagValue("kv3", d, "Reactive Power 3"),
            ...getCustomTagValue("t_kv", d, "Total Reactive Power"),
            ...getCustomTagValue("kva1", d, "Apparent Power 1"),
            ...getCustomTagValue("kva2", d, "Apparent Power 2"),
            ...getCustomTagValue("kva3", d, "Apparent Power 3"),
            ...getCustomTagValue("t_kva", d, "Total Apparent Power"),
            ...getCustomTagValue("freq", d, "Frequency"),
            RSSI: d["rssi"],
          };
        });
      } 
       else if (template === 42) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            "Temperature (°C)": d["temp"],
            "Humidity (%)": d["hum"],
            Status: d["ac_status"],
            "Set Temp (°C)": d["set_temp"],
            RSSI: d["rssi"],
          };
        });
      }
      else if (template === 43) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            TimeStamp: formatTimestamp(d.timeStamp),
            "Temperature (°C)": d["temp"],
            "Humidity (%)": d["hum"],
            Status: d["buz"],
            "Set Temp (°C)": d["set_temp"],
            RSSI: d["rssi"],
          };
        });
      }

      if (role.role === 2) {
        _data = _data.filter((d: any) => {
          const containsError = (value: any) =>
            typeof value === "string" && value.toLowerCase().includes("error");
          return !Object.values(d).some(containsError);
        });
      }
      setApiData(_data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  function formatTimestamp(timestamp: string) {
    return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
  }

  function calculateFlow(_data: any, i: any) {
    if (i === _data.length - 1) {
      return getSanitizedValue(0);
    }
    const currentData = _data[i];
    const previousData = _data[i + 1];

    if (
      currentData["Differential Total Flow"] === "ERROR" ||
      previousData["Differential Total Flow"] === "ERROR"
    ) {
      return getSanitizedValue(0);
    }

    const currentFlow =
      currentData["Differential Total Flow"] !== "ERROR"
        ? +currentData["Differential Total Flow"]
        : 0;
    const previousFlow =
      previousData["Differential Total Flow"] !== "ERROR"
        ? +previousData["Differential Total Flow"]
        : 0;
    const timeDiff =
      +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
    let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
    if (flow < 0) {
      flow = 0;
    }
    const v = getSanitizedValue(flow * 0.001);
    if (isNaN(v)) {
      return 0;
    }

    return v;
  }

  function getSanitizedValue(value: any) {
    if (typeof value === "number") {
      return value.toFixed(3);
    } else {
      return value;
    }
  }

  let filterData1: any;
  if (role.role === 1) {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      ...row,
    }));
  } else {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      ...row,
    }));
  }

  filterData1.sort(
    (
      a: { timestamp: string | number | Date },
      b: { timestamp: string | number | Date }
    ) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const handleDataLogDelete = async () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete these logs?"
    );

    if (shouldDelete) {
      try {
        const resp: any = await http(`/device-data-log`, "DELETE", selected);
        if (startValue["$d"] && endValue["$d"]) {
          const startDate: any = new Date(startValue["$d"]);
          const endDate: any = new Date(endValue["$d"]);
          if (startDate !== "Invalid Date" && endDate !== "Invalid Date") {
            getData({ startDate, endDate, deviceId });
          }
        }
        toast(resp.message);
        setSelected([]);
      } catch (error) {
        console.error(error);
      }
    } else {
    }
  };

  const handleClickAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(apiData.map((row: any) => row.logId));
    }
  };

  const toggleFilterList = () => {
    setFilterTable(!filterTable);
  };

  const handleSelectedDataChange = (selectedData: string[]) => {
    setHeaders(selectedData);
    setOptionColumns(selectedData);
  };

  const handleExportReport = () => {
    const startDate: any = new Date(startValue["$d"]);
    const endDate: any = new Date(endValue["$d"]);
    const startDateFormatted = startDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    // Convert end date to desired format
    const endDateFormatted = endDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    let tName = "";
    // console.log("tName", tName);
    switch (device.template) {
      case 5:
      case 6:
      case 9:
      case 15:
      case 16:
      case 24:
      case 31:
      case 32:
      case 33:
      case 34:
        case 49:
      case 37:
        tName += "Water Consumption Summary";
        break;
      case 7:
      case 12:
      case 13:
      case 17:
      case 18:
      case 19:
        tName += "Water Quality Summary";
        break;
      case 8:
      case 22:
      case 23:
        tName += "Vibration Summary";
        break;
      case 10:
      case 20:
        tName += "Temperature & Humidity Summary";
        break;
      case 11:
      case 21:
        tName += "Automation Summary";
        break;
        case 45:
          tName += "Custom Tag Summary";
          break;
      case 14:
      case 38:
      case 51:
      case 41:
        tName += "Energy Summary";
        break;
      case 27:
        tName += "Atlanta Summary";
        break;
      case 26:
        tName += "Solar Summary";
        break;
      case 25:
        tName += "DG Summary";
        break;
      case 28:
        tName += "Temperature Summary";
        break;
      case 29:
      case 47:
      case 35:
        tName += "Level Summary";
        break;
      case 42:
        case 43:
        tName += "Temperature, Humidity & AC Status Summary";
        break;
      default:
        return;
    }

    if (template === 9) {
      reportData1(
        apiData,
        ["timestamp", "totalizer", "unit"],
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted
      );
    } else {
      reportData(
        device?.template,
        apiData,
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted,
        device,
        deviceTemplate,
        customTag1
      );
    }
  };

  const handleRawExport = () => {

    const wb = XLSX.utils.book_new();
    if (apiData1.length === 0) {
      return
    }

    const _data1 = apiData1.filter((d: any) => {
      const containsError = (value: any) =>
        typeof value === "string" && value.toLowerCase().includes("error");
      return !Object.values(d).some(containsError);
    }).map((d: any) => ({
      ...d,
      timeStamp: formatTimestamp(d.timeStamp),
    }));

    // const _data2 = _data1.map((d: any) => ({
    //   ...d,
    //   timeStamp: formatTimestamp(d.timeStamp),
    // }));
    // console.log(_data2, "9099");

    // Convert JSON to a worksheet
    const ws = XLSX.utils.json_to_sheet(_data1);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    // Generate buffer and save the file
    XLSX.writeFile(wb, "data.xlsx");
  }

  const handleExportBarChart = async (exportOption: string) => {
    // const device = devices.devices.find(
    //   (device: any) => device.id === deviceId
    // );
    switch (exportOption) {
      case "Download Raw":
        handleRawExport()
        break;
      case "Download as PDF":
        handleExportReport();
        break;
      case "Download as CSV":
        if (template === 14) {
          generateCSV(
            optionColumns.length === 0 ? energy : optionColumns,
            apiData
          );
        } else if (template === 38) {
          generateCSV(
            optionColumns.length === 0 ? energyMonitoring : optionColumns,
            apiData
          );
        } else if (template === 41) {
          generateCSV(
            optionColumns.length === 0 ? energySinglePhase : optionColumns,
            apiData
          );
        }else if (template === 51) {
          generateCSV(
            //awfisEnergyData
            optionColumns.length === 0 ? awfisEnergyData : optionColumns,
            apiData
          );
        }
         else if (
          template === 5 ||
          template === 26 ||
          template === 25 ||
          template === 9
        ) {
          generateCSV(headers, apiData);
        } else {
          generateCSV1(
            apiData,
            device.template,
            device,
            deviceTemplate,
            customTag1
          );
        }

        break;
      case "Download as Report":
        let column = ["Date", "FirstTimeStamp", "LastTimeStamp", "Consumption"];
        let date = apiData[0].timestamp;
        let lastTimeStamp = apiData[0].timestamp;
        let firstTimeStamp = apiData[apiData.length - 1].timestamp;
        let consumption =
          apiData[apiData.length - 1].totalizer - apiData[0].totalizer;

        if (consumption < 0) {
          consumption = consumption * -1;
        }

        let resultObject = {
          Date: date?.slice(0, 10),
          FirstTimeStamp: firstTimeStamp,
          LastTimeStamp: lastTimeStamp,
          Consumption: consumption,
        };
        reportData1(
          [resultObject],
          column,
          device.deviceName,
          device?.Customer?.name || "N/A",
          "Totalizer Summary",
          formatIndianTime(startValue["$d"]) +
          " - " +
          formatIndianTime(endValue["$d"])
        );
        break;
      default:
        return; // Unknown export option
    }
  };



  return (
    <div className="px-4">
      <div>
        <p className="text-xl font-semibold text-slate-500">{deviceName}</p>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex">
          <div className="mr-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Start date & time"
                  // value={startValue}
                  onChange={(newStartValue) => setStartValue(newStartValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="End date & time"
                  // value={endValue}
                  onChange={(newEndValue) => setEndValue(newEndValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ProtectedComponent>
            {selected.length > 0 && (
              <IconButton onClick={handleDataLogDelete}>
                <DeleteIcon />
              </IconButton>
            )}
            {apiData.length > 0 && (
              <IconButton onClick={handleClickAll}>
                <Checkbox checked={selected.length > 0} />
              </IconButton>
            )}
          </ProtectedComponent>
          {(template === 5 ||
            template === 14 ||
            template === 38 ||
            template === 41 ||template === 51) && (
              <IconButton onClick={toggleFilterList}>
                <FilterListIcon />
              </IconButton>
            )
            
            }

          <LongMenu
            options={[
              template === 38 || template === 41 || template === 14|| template === 51
                ? ""
                : "Download as PDF",
              "Download as CSV",
              template === 9 ? "Download as Report" : "",

              users.user.customerId ? "" : "Download Raw",
            ]}
            onOptionClick={handleExportBarChart}
            id={1}
          />
        </div>
      </div>
      <div className="mt-10">
        {selectedOption === "chart" && (
          <div className="graph-list">
            <p className="border-b border-slate-300 pb-2 text-lg text-blue-500">
              Device Name
            </p>
            <div className="flex justify-center mt-4">
              {chartData ? (
                <div>
                  <CustomLineChart {...chartData} height={300} width={500} />
                </div>
              ) : (
                <p>No Data Available</p>
              )}
            </div>
          </div>
        )}
        {selectedOption === "table" && (
          <div className="tabular-data ">
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <CircularProgress />
              </div>
            ) : (
              <TabularComponents2
                headers={headers}
                rows={filterData1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                onRowClick={(row) => console.log("Row clicked:", row)}
                currentPage={pagination.currentPage}
                totalItems={pagination.totalItems}
                totalPages={pagination.totalPages}
              />
            )}
          </div>
        )}
      </div>
      <Dialog
        open={filterTable}
        onClose={() => {
          setFilterTable(false);
        }}
      >
        <CheckBoxFilter
          data={
            template === 5
              ? header
              : template === 38
                ? energyMonitoring
                : template === 41
                  ? energySinglePhase
                  : template === 51 ?
                    awfisEnergyData
                    : energy
          }
          onSelectedDataChange={handleSelectedDataChange}
        />
      </Dialog>
    </div>
  );
};

export default Data;
