import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  Box,
  Table,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';//EditIcon
import EditIcon from '@mui/icons-material/Edit';//EditIcon

import { http } from "../helper/http";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

const AdminPayment = () => {


  const token: any = window.localStorage.getItem("token");
  const decodedToken = jwtDecode<any>(token);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [devices, setDevices] = useState([]); // Holds the device data
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [paymentResponce, setPaymentResponce] = useState<any>([]);
  const [id, setId] = useState('');
  const [action, setAction] = useState('ADD');

  const [formData, setFormData] = useState({
    deviceId: "",
    litre: "",
    rupees: "",
  });

  const litres = [1];

  // Fetch devices from the API
  const fetchDevices = async (page: number) => {
    try {
      const resp: any = await http(`${baseUrl}/device?templateId=49`);

      console.log(`devices:`, resp);

      const newTable: any = resp.devices;
      setDevices(newTable); // Update the devices state with fetched data
      setTotalPages(resp.devices.totalPages);
      setTotalItems(resp.devices.totalItems);
      setCurrentPage(resp.devices.currentPage);
    } catch (err) {
      console.error("Failed to fetch devices:", err);
    }
  };

  useEffect(() => {
    fetchDevices(currentPage);
    getResponce();
  }, [currentPage]);

  const handleEdit = async (row: any) => {
    setId(row.id);

    setAction('EDIT');
    // setIsEditing(true);
    try {
      // const response = await http(`http://localhost:8080/device-useage?id=${row.id}`, 'PUT',formData)


      setFormData({
        deviceId: row.deviceId,
        litre: row.litre,
        rupees: row.rupees,
      });
    } catch (err) {
      console.error("Error fetching the data for edit:", err);
    }
    setFormData(row);
  };

  const handleDelete = async (row: any) => {
    try {
      const response = await axios.delete(`${baseUrl}/device-useage?id=${row.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getResponce();
      alert(response.statusText);
      getResponce();
    } catch (err) {
      console.error("Error fetching the data for edit:", err);
    }
  };



  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {


      if (action === 'ADD') {
        const response = await axios.post(`${baseUrl}/device-useage`, formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json, text/plain, */*"
            }
          }

        );
        toast.success(response.statusText);
      } else {
        const response = await http(`${baseUrl}/device-useage?id=${id}`, 'PUT', formData)

        setAction('ADD');
        setFormData({ deviceId: "",
          litre: "",
          rupees: "",});

      }

    
      alert("Data submitted successfully!");

      getResponce();
    } catch (error) {
      console.error("Error while submitting data:", error);
      alert("Failed to submit data. Please try again.");
    }
  };
  const getResponce = async () => {
    console.log("Form Data:", formData);
    try {
      const response = await axios.get(`${baseUrl}/device-useage`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*"
        }
      });
      console.log("Response from server:", response.data);
      setPaymentResponce(response.data);
    } catch (error) {
      console.error("Error while submitting data:", error);
    }
  };
  return (
    <div className="border">

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "row", gap: 2 }}
      >
        {/* Device ID Dropdown */}
        <FormControl fullWidth>
          <InputLabel id="deviceId-label">Device ID</InputLabel>
          <Select
            labelId="deviceId-label"
            name="deviceId"
            value={formData.deviceId}
            onChange={handleChange}
            label="Device ID"
          >
            {devices.map((device: any) => (
              <MenuItem key={device.id} value={device.id}>
                {device.deviceId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
  name="litre"
  label="Litre"
  type="number"
  value={formData.litre}
  onChange={handleChange}
  fullWidth
/>



        {/* Rupees Input */}
        <TextField
          name="rupees"
          label="Rupees"
          type="number"
          value={formData.rupees}
          onChange={handleChange}
          fullWidth
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>

      <Table sx={{ width: '100%', border: '1px solid black', borderRadius: '8px', marginTop: '20px' }}>
        <thead>
          <tr style={{ backgroundColor: '#0A083B', color: 'white', textAlign: 'left' }}>
            <th style={{ width: 'var(--Table-firstColumnWidth)', color: 'white', textAlign: 'left' }}>ID</th>
            {/* <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Device ID&nbsp;</th> */}
            <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Litre&nbsp;</th>
            <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Rupees&nbsp;</th>
            {/* <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Template ID&nbsp;</th> */}
            <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Action&nbsp;</th>

          </tr>
        </thead>

        <tbody>
          {paymentResponce?.deviceUsages?.map((row: any) => (
            <tr style={{ border: '1px solid black' }} key={row.id}>
              <td>{row.device.deviceName}</td>
              {/* <td>{row.deviceId}</td> */}
              <td>{row.litre}</td>
              <td>{row.rupees}</td>
              {/* <td>{row.templateId}</td> */}
              <td>

                <div style={{ margin: "5px", width: '30px',display:'flex' }}>
                  {/* <im onClick={() => handleEdit(row)}>{DeleteIcon}</Button> */}
                  <EditIcon sx={{ width: '30px' }} onClick={() => handleEdit(row)} />
                  <DeleteIcon sx={{ width: '30px' }} onClick={() => handleDelete(row)} />
                </div>
              </td>
             
            </tr>
          ))}
        </tbody>
      </Table>

    </div>
  );
};

export default AdminPayment;
