import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge'; // Material-UI Badge for the notification count
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { http } from '../../helper/http';
import { formatDateToIST } from '../../utils/format-date-utc/UtcDate';

const NotificationBar = () => {


  const token: any = window.localStorage.getItem("token");
  const decodedToken = jwtDecode<any>(token);
  const isSmallScreen = useMediaQuery({ maxWidth: 700 });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState<any>();
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const [markAllRead, setMarkAllRead] = useState(false);

  // Fetch notifications using Axios
  const baseUrl = process.env.REACT_APP_BASE_URL;



  // const fetchNotifications = async () => {

  //   try {
  //     const response: any = await http(`${baseUrl}/notification?customerId=${decodedToken?.customerId}`, 'GET');
  //     if (markAllRead === true) {
  //       const response: any = await http(`${baseUrl}/notification?customerId=${decodedToken?.customerId}`, 'PATCH');
  //     }
  //     setNotifications(response);
  //   } catch (error) {
  //     console.error('Error fetching notifications:', error);
  //   }
  // };

  const fetchNotifications = async () => {
    try {
      if (markAllRead) {
        // Mark all notifications as read
        await http(
          `${baseUrl}/notification?customerId=${decodedToken?.customerId}`,
          'PATCH'
        );
        setMarkAllRead(false); // Reset the state after marking all read
      }
  
      // Fetch updated notifications
      const response: any = await http(
        `${baseUrl}/notification?customerId=${decodedToken?.customerId}`,
        'GET'
      );
      setNotifications(response.length > 0?response:[]);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    }
  };
  

  const MarkChatRead = async (notification: any) => {
    const payload = {
      ...notification,
      read: true,
    };
    // const payload = {
    //   "deviceId": notification?.deviceId,
    //   "userId": notification?.userId,
    //   "customerId": notification?.customerId,
    //   "message": notification.message,
    //   "read": true,
    // }
    try {
      const response: any = await http(`${baseUrl}/notification?id=${notification?.id}`, 'PUT', payload);
      if(response){
        fetchNotifications();
      }
      
    } catch (error) {
      console.log(`my error for notification dismis`, error);

    }
  }

  //create faileed notification
//const result = await axios.put(`${baseUrl}/payment`, data, {}


  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, 60000); // 60000 ms = 1 minute
    return () => clearInterval(interval);
  }, []);


  // Close on click outside
  useEffect(() => {
    if (decodedToken.menu.recharge) {
      fetchNotifications();
    }

    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  const handleFocus = () => {
    setDropdownOpen(true);
  };

  const handleBlur = () => {
    setDropdownOpen(false);
  };

  return (
    <li className="relative">
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        to="#"
        className="relative flex h-8.5 w-8.5 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray hover:text-primary hover:bg-gray-300"
      >
        {/* Badge showing notification count */}
        <Badge badgeContent={notifications?.length} color="error">
          <NotificationsNoneIcon sx={{ fontSize: '2.2rem' }} />
        </Badge>
      </Link>

      <div
        ref={dropdown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`absolute right-0 mt-2.5 p-3 flex h-90 flex-col rounded-sm border border-stroke bg-white shadow-md ${dropdownOpen ? 'block' : 'hidden'
          } ${isSmallScreen ? 'w-[70vw] right-0' : 'w-[24vw]'}`}
      >
        <div className="px-4.5 py-3 flex justify-between">
          <h5 className="text-sm font-medium text-bodydark2">Notification</h5>
          <p onClick={() => {

            setMarkAllRead(true)
            fetchNotifications();
          }} className=' cursor-pointer text-[10px] border borderbg-sky-500 items-centerbg-green-600 p-1  
                rounded-md hover:bg-green-100'>Mark All Read</p>
        </div>

        <ul className="flex h-[150px] flex-col overflow-y-auto">
          {notifications?.length > 0 ? (
            notifications.map((notification: any, index: any) => (
              <li key={index}>
                <Link
                  className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2"
                  to="#"
                >
                  <p className="text-sm">
                    <span className="text-black">{notification.title}</span>{' '}
                    {notification.message}
                  </p>
                  <p className="text-xs">{formatDateToIST(notification.createdAt)}</p>
                  <p className='text-[10px] border  text-center' onClick={() => MarkChatRead(notification)}>Mark Read</p>
                </Link>
                <hr></hr>
              </li>
            ))
          ) : (
            <li className="text-center py-3 text-gray-500">No notifications</li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default NotificationBar;
