import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg";

interface LineData {
  name: string;
  color: string;
  data: { day: string; avgkwh: string }[];
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  yAxisName?: string;
  xAxisName?: string;
  stroke?: boolean;
}

const LineChartForSelectedDate: React.FC<LineChartProps> = ({ data, yAxisName, xAxisName, stroke }) => {
  // Extract categories (days) from the first dataset
  const categories = data.length > 0 ? data[0].data.map((point) => point.day) : [];
  console.log("catagories", data);
  

  // Create series from the data
  const series = data.map((line) => ({
    name: line.name,
    data: line.data.map((point) => parseFloat(point.avgkwh)), // Convert avgkwh to numbers
  }));

  console.log("series", series);
  

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisName || "X Axis",
      },
    },
    yaxis: {
      title: {
        text: yAxisName || "Y Axis",
      },
      labels: {
        formatter: function (value: any) {
          return parseFloat(value).toFixed(3); // Format labels to 3 decimal places
        },
      },
    },
    tooltip: {
      x: {
        formatter: function (val: any, opts: any) {
          return categories[opts.dataPointIndex]; // Show the day in tooltip
        },
      },
      y: {
        formatter: function (value, { seriesIndex }) {
          const postfix = data[seriesIndex].postfix || "";
          return value.toFixed(3) + postfix; // Add postfix if provided
        },
      },
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    colors: data.map((line) => line.color), // Use colors from data
    stroke: stroke
      ? {
          width: 2,
          curve: "smooth",
        }
      : undefined,
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};


export default LineChartForSelectedDate