import Calendar from "../modules/calender/Calender";
import EditProfile from "../modules/profile/editProfile/EditProfile";
import DashBoard from "../modules/dashboard/DashBoard";
import { Devices } from "../modules/admin/devices/Devices";
import Tags from "../modules/admin/tags/Tags";
import AccountSettings from "../modules/accountSettings/AccountSettings";
import Templates from "../modules/admin/templates/Template";
import Data from "../modules/admin/devices/Data";
import TagGroup from "../modules/admin/tag-groups/TagGroup";
import Users from "../modules/admin/users/Users";
import CsvUpload from "../modules/csv-upload/CsvUpload";
import Report from "../modules/report/Report";
import Alert from "../modules/alert/Alert";
import WhiteLabelForm from "../modules/dashboard/whiteLeveling/Whiteleveling";
import ModbusConfig from "../modules/admin/devices/ModbusConfig";
import DashbaordWater from "../modules/dashboard/DashbaordWater";
import Overviewpage from "../modules/dashboard/Overviewpage";
import AssignDevices from "../modules/device-management/AssignDevice";
import CustomerDashboard from "../modules/dashboard/CustomerDashboard";
import MeterReadingData from "../modules/report/MeterReadingData";
import ConsumptionSummary from "../modules/report/ConsumptionSummary";
import Report1 from "../modules/report/Report1";
import CustomTags from "../modules/admin/customTags/CustomTags";
import DeviceTemplate from "../modules/admin/devices/DeviceTemplate";
import MeterReadingData1 from "../modules/report/MeterReadingData1";
import ConsumptionSummary1 from "../modules/report/ConsumptionSummary1";
import ReportTable from "../modules/report/ReportTable";
import F6_Config_Template from "../modules/admin/f6_Config/F6_Template_Config";
import UserDashboard from "../modules/dashboard/DynamicDashboard";
import Scheduling from "../modules/scheduling/Scheduling";
import ACUserDashboard from "../modules/dashboard/ACUserEndDashboard/ACUserDashboard";
import Payment from "../payment/Razorpay-payment";
import AdminPayment from "../payment/AdminPayment";
import SchedulerAlertData from "../modules/dashboard/SchedulerAlertData";
import DeviceStatus from "../modules/admin/devices/DeviceStatus";

export  const routes = [
  { path: "/calendar", component: Calendar },
  { path: "/editprofile", component: EditProfile },
  { path: "/", component: DashBoard },
  { path: "/dashboard", component: CustomerDashboard },
  
  { path: "/dashbaordWater", component: DashbaordWater },
  // {path:'/home/customer', component:Customers},
  { path: "/device", component: Devices },
  { path: "/online-devices", component: DeviceStatus },
  { path: "offline-devices", component: DeviceStatus },
  { path: "/useroverview", component: Overviewpage },
  {path: "/userdashboard", component: UserDashboard},
  {path: "/acdashboard", component: ACUserDashboard},
  { path: "/tags", component: Tags },
  { path: "/settings", component: AccountSettings },
  { path: "/template", component: Templates },
  { path: "/data", component: Data },
  { path: "/modbusConfig", component: ModbusConfig },
  { path: "/tag-group", component: TagGroup },
  { path: "/users", component: Users },
  { path: "/device/csv-upload/:id", component: CsvUpload },
  { path: "/reports/:customerId", component: Report },
  { path: "/reports1/:customerId", component: Report1 },
  { path: "/alert", component: Alert},
  { path: "/AssignDevice", component: AssignDevices},
  {path:'/whitelevel', component: WhiteLabelForm},
  { path: "/meter-reading-data/:customerId", component: MeterReadingData },
  { path: "/meter-reading-data1/:customerId", component: MeterReadingData1 },
  { path: "/consumption-summary/:customerId", component: ConsumptionSummary },
  { path: "/consumption-summary1/:customerId", component: ConsumptionSummary1 },
  { path: "/report-table", component: ReportTable },



  {path:'/edit-tags/:id/:template', component:CustomTags},
  {path: '/device-template', component: DeviceTemplate},
  {path: '/f6-template-config/:id', component: F6_Config_Template},
  {path: '/scheduling', component: Scheduling},
  {path: '/scheduleAlert', component: SchedulerAlertData},
  {path:'/recharge',component:Payment},
  {path:'/payment',component:AdminPayment}


  // {path:'/home/crm', component:Permission}
];
