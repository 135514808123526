import React, { useState, useEffect, } from 'react';
import {
    Box,
    Table,
    Modal, Typography, Button, TextField
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { http } from '../helper/http';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { fetchNotifications, Notification } from '../utils/notifications/GlobalNotifications';
// import { toast } from 'react-toastify';

interface User {
    id: number;
    name: string;
}


const formatDateToIST = (isoDate: string): string => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
};
const baseUrl = process.env.REACT_APP_BASE_URL;
const Payment = () => {
    const [razorpayLoaded, setRazorpayLoaded] = useState(false);

    const loadRazorpayScript = () => {
        if (!razorpayLoaded) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => setRazorpayLoaded(true);
            document.body.appendChild(script);
        }
    };

    //token 
    const token: any = window.localStorage.getItem("token");
    const decodedToken = jwtDecode<any>(token);

    const [open, setOpen] = useState(false);
    const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
    const [selectedImei, setSelectedImei] = useState(null);
    const [users, setUsers] = useState<User[]>([]);




    const handleClose = () => {
        setSelectedDevices([]);
        setSelectedImei(null);
        setOpen(false);
    };

    const [devices, setDevices] = useState([]);

    const [selectedDevices, setSelectedDevices] = useState<User[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState<any>(null);
    const [paymentHistory, setPaymentHistory] = useState<any>([]);

    // const [AllDevice, setAllDevices] = useState([]);
    const [order, setOrder] = useState<any>(null);  // Initialize state to store the order
    const [payment, setPayment] = useState<any>(null);
    const [amount, setAmount] = useState('')
    const [rowId, setRowId] = useState()

    const fetchUsers = async (deviceId: any) => {
        try {
            const resp: any = await http(`/filter-user-device?deviceId=${deviceId}`);
            const newCustomers = resp.filterUsers;
            setUsers(newCustomers);
        } catch (err) {
            console.error("Failed to fetch users:", err);
        }
    };

    const handleImeiChange = (event: any, value: any) => {
        setSelectedImei(value.id);
        fetchUsers(value.id);
    };


    const handleDeleteClick = (device: any) => {
        setDeviceToDelete(device);
        setDeleteOpen(true);
    };


    const loadNotifications = async () => {
        const baseUrl = process.env.REACT_APP_BASE_URL || '';
        const customerId = decodedToken?.customerId // Example customer ID
        const notifi: Notification[] = await fetchNotifications(baseUrl, customerId, true);
        console.log('Fetched Notifications:', notifi);
    };




    const fetchDevices = async (page: number) => {
        try {
            const resp: any = await http(`${baseUrl}/device?page=${page}`);
            const newTable: any = resp.devices.devices;
            setDevices(newTable);
            setTotalPages(resp.devices.totalPages);
            setTotalItems(resp.devices.totalItems);
            setCurrentPage(resp.devices.currentPage);
        } catch (err) {
            console.error("Failed to fetch devices:", err);
        }
    };

    ///notification
    // post
    // payload


    useEffect(() => {

        fetchDevices(currentPage);
        loadNotifications();
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => console.log('Razorpay script loaded.');
        script.onerror = () => console.error('Error loading Razorpay script.');
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        // getAllDevices();
    }, [currentPage]);

    // const deviceData = AllDevice.map((device: any) => ({
    //     id: device?.id,
    //     deviceName: device?.deviceId,
    // }));

    const handlePageChange = async (newPage: number) => {
        await fetchDevices(newPage + 1);
    };



    function getUserNameById(id: number) {
        // return (users.find((d: any) => d.id == id) as any).name;
        const user = users.find((user) => user.id === id);
        return user ? user.name : '';
    }



    const handlePayment = async () => {
        try {
            // Validate necessary fields
            if (!amount || !decodedToken.customerId || !decodedToken.id || !token) {
                console.error("Required data missing");
                return;
            }

            // Load Razorpay script if not already loaded
            if (!window.Razorpay) {
                console.error("Razorpay script not loaded");
                await loadRazorpayScript();
            }

            // Prepare payment data
            const paymentData = {
                amount: amount,
                deviceId: rowId,
                customerId: decodedToken.customerId,
                userId: decodedToken.id,
            };

            // Create order on the server
            const response = await axios.post(`${baseUrl}/payment`, paymentData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json, text/plain, */*",
                },
            });

            // Extract order details from the response
            const { order, payment } = response.data;
            setOrder(order);
            setPayment(payment);

            // Razorpay payment options
            const options = {
                key: "rzp_live_R0SoTCvNQF8Qfc", // Your Razorpay key
                amount: order.amount, // Amount in paisa
                currency: "INR",
                name: "Frinso",
                description: "Test Transaction",
                image: "https://yourdomain.com/your-logo.png",
                order_id: order.id, // Razorpay order ID
                handler: async function (response: any) {
                    console.log("Payment Success Response:", response);

                    const successData = {
                        paymentId: response.razorpay_payment_id,
                        orderId: response.razorpay_order_id,
                        signature: response.razorpay_signature,
                        deviceId: rowId,
                        userId: decodedToken.id,
                        amount: order.amount,
                        customerId: decodedToken.customerId,
                    };

                    try {
                        const result = await axios.put(`${baseUrl}/payment`, successData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: "application/json, text/plain, */*",
                            },
                        });
                        loadNotifications();
                        alert(result.data.message);

                    } catch (error) {
                        console.error("Error confirming payment success:", error);
                    }
                },
                prefill: {
                    name: "Customer Name", // Replace with actual customer name
                    email: "customer@example.com", // Replace with actual customer email
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#F37254",
                },
            };

            const razorpay = new window.Razorpay(options);

            // Handle payment failure
            razorpay.on("payment.failed", async (response: any) => {
                console.error("Payment Failed Response:", response);

                const failureData = {
                    paymentId: response.error.metadata.payment_id,
                    orderId: response.error.metadata.order_id,
                    status: "failed",
                    reason: response.error.reason,
                    step: response.error.step,
                    description: response.error.description,
                    deviceId: rowId,
                    userId: decodedToken.id,
                    amount: order.amount,
                    customerId: decodedToken.customerId,
                };

                try {
                    await axios.put(`${baseUrl}/payment`, failureData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/json, text/plain, */*",
                        },
                    });
                    alert("Payment failed. Please try again.");
                    loadNotifications();
                } catch (error) {
                    console.error("Error logging failed payment:", error);
                }
            });

            // Open Razorpay payment modal
            razorpay.open();
            setAmount(''); // Clear the amount input
            setOpen(!open);
        } catch (error) {
            console.error("Payment creation failed:", error);
        }
    };


    const handleOpenModal = async (row: any) => {
        try {
            const presentDeviceUsers: any = await http(`${baseUrl}/device-useage?deviceId=${row.id}`)
            if (presentDeviceUsers.deviceId) {
                setRowId(row.id)
                setOpen(!open);
            }
            console.log('mera shaumya', presentDeviceUsers.deviceId);
        } catch (error) {

            console.log('mera shaumya from catch', error);

        }
    };

    const handleHistory = async (row: any) => {

        try {
            const responce: any = await http(`${baseUrl}/payment?deviceId=${row.id}`)
            if (responce && responce.payments) {
                setPaymentHistory(responce.payments);

                setOpenPaymentHistory(!openPaymentHistory);
            }

        } catch (error) {
            console.log('mera shaumya from catch', error);
        }
    };



    return (
        <>
            <Table sx={{ width: '100%', border: '1px solid black', borderRadius: '8px', padding: '50px' }}>
                <thead className='p-3'>
                    <tr style={{ backgroundColor: '#0A083B', color: 'white', textAlign: 'left', padding: '20px' }}>
                        {/* <th style={{ width: 'var(--Table-firstColumnWidth)', color: 'white', textAlign: 'left' }}>ID</th> */}
                        <th style={{ width: 200, color: 'white', textAlign: 'left', padding: '10px' }}>Device IMEI</th>
                        <th style={{ width: 200, color: 'white', textAlign: 'left', padding: '10px' }}>Device Name&nbsp;</th>
                        {/* <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Email&nbsp;</th> */}
                        <th style={{ width: 200, color: 'white', textAlign: 'left', padding: '10px' }}>Action&nbsp;</th>
                    </tr>
                </thead>
                <tbody className='p-3'>
                    {devices.map((row: any) => (
                        <tr style={{ border: '1px solid black', padding: '10px' }} key={row.id}>
                            {/* <td>{row.id}</td> */}
                            <td className='p-3'>{row.deviceId}</td>
                            <td className='p-3'>{row.deviceName}</td>
                            {/* <td>{row.User?.email}</td> */}
                            {row.template === 49 ? <td>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {/*  */}
                                    <div style={{ margin: "2%" }}>
                                        <Button sx={{}} onClick={() => handleOpenModal(row)}>Pay Now</Button>
                                        <Button sx={{}} onClick={() => handleHistory(row)}>History</Button>
                                    </div>

                                </Box>
                            </td> : <td><Box sx={{ display: 'flex', gap: 1 }}>
                                {/*  */}
                                <div style={{ margin: "2%", }}>
                                    <Button sx={{ color: 'grey' }}>Pay Now</Button>

                                </div>

                            </Box></td>}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalItems}
                rowsPerPage={10}
                page={Math.max(
                    0,
                    Math.min(currentPage - 1, totalPages - 1)
                )}
                onPageChange={(event, newPage) => handlePageChange(newPage)}
                className="bg-gray-100"
            />

            <Modal open={open} onClose={() => { setOpen(false) }}>
                <Box
                    sx={{
                        ...modalStyle,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 24
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                        Enter Amount To Recharge
                    </Typography>

                    <TextField
                        id="amount-input"
                        type="number"
                        label="Enter Amount"
                        variant="outlined"
                        fullWidth
                        value={amount}
                        onChange={(event) => setAmount(event.target?.value)}
                        InputProps={{
                            inputProps: { min: 0 }, // Prevent negative values
                        }}
                    />

                    <Button
                        variant="contained"
                        color="success"
                        size="large"
                        onClick={() => handlePayment()}
                        sx={{ mt: 2, width: '100%' }}
                    >
                        Pay Now
                    </Button>
                </Box>
            </Modal>

            {/* payment history */}

            <Modal open={openPaymentHistory} onClose={() => setOpenPaymentHistory(false)}>
                <Box
                    sx={{
                        ...modalStyle,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        p: 1,
                        borderRadius: 2,
                        boxShadow: 24,
                        width: '60%',
                        height: '60%',
                        maxWidth: '90vw',
                        maxHeight: '90vh',
                        overflow: 'auto',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                        Payment History
                    </Typography>
                    <Table
                        sx={{
                            width: '100%',
                            border: '1px solid black',
                            borderRadius: '8px',
                            padding: '50px',
                        }}
                    >
                        <thead className="p-3">
                            <tr
                                style={{
                                    backgroundColor: '#0A083B',
                                    color: 'white',
                                    textAlign: 'left',
                                    padding: '20px',
                                }}
                            >
                                <th
                                    style={{
                                        width: 200,
                                        color: 'white',
                                        textAlign: 'left',
                                        padding: '8px',
                                    }}
                                >
                                    Device
                                </th>
                                <th
                                    style={{
                                        width: 200,
                                        color: 'white',
                                        textAlign: 'left',
                                        padding: '8px',
                                    }}
                                >
                                    Amount Paid &nbsp;
                                </th>
                                <th
                                    style={{
                                        width: 200,
                                        color: 'white',
                                        textAlign: 'left',
                                        padding: '8px',
                                    }}
                                >
                                    Payment Date &nbsp;
                                </th>
                                <th
                                    style={{
                                        width: 200,
                                        color: 'white',
                                        textAlign: 'left',
                                        padding: '8px',
                                    }}
                                >
                                    Status&nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody className="p-2">
                            {paymentHistory.map((row: any) => (
                                <tr style={{ border: '1px solid black', padding: '8px' }} key={row.id}>
                                    <td className="p-2 text-sm">{row.Device.deviceId}</td>
                                    <td className="p-2 text-sm">{row.amount}</td>
                                    <td className="p-2 text-sm">{formatDateToIST(row.Transaction.createdAt)}</td>
                                    <td className="p-2 text-sm">{row.Transaction.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Box>
            </Modal>


        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Payment;
